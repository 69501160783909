<template>
  <div class="first-page">

    <pdf-header
      :attachmentNumber="attachmentNumber"
      :pageTitle="pageTitle"
      :mainTitle="mainTitle"

      :instituteName="instituteName"
      :notice="notice"

      :localGovermentUnit="localGovermentUnit"
      :localGovermentUnitOnly="localGovermentUnitOnly"
    />

    <div class="first-page__content">
      <p class="paragraph__main-title" v-html="mainTitle"></p>
      <p class="paragraph__notice"> {{ notice }}</p>


      <div class="first-page__content__user-input">

        <div class="first-page__content__user-input__section">
          <p>
            {{ bankText1 }}
            <input type="text"
              :value="getInputValue('value')"
              @input="updateModel('value', $event.target.value, true)"
            > zł
          </p>

          <p class="first-page__content__user-input--complete first-page__content__user-input--italic">
            (słownie:
            <input type="text"
              :value="getInputValue('valueVerbally')"
              @input="updateModel('valueVerbally', $event.target.value)"
            >)
          </p>

          <div class="first-page__content__user-input--complete">
            <p>
              {{ bankText2 }}
            </p>
            <div class="first-page__content__user-input--complete__label">
              <input type="text"
                :value="getInputValue('bankInfo')"
                @input="updateModel('bankInfo', $event.target.value)"
              >
              <label class="">{{ bankText3 }}</label>
            </div>
          </div>
        </div>

        <div class="first-page__content__user-input__section">
          <p>
            {{ attachmentText }}
          </p>
        </div>

      </div>

      <pdf-signs />

      <div class="first-page__content__additional-info">
        <p>
          {{ attachmentText2 }}
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import PdfHeader from '../../Shared/PdfHeader'
import PdfSigns from '../../Shared/PdfSigns.vue'

export default {
  name: 'PageOne',

  data: function () {
    return { }
  },

  props: {
    attachmentNumber: String,
    pageTitle: String,
    mainTitle: String,
    instituteName: String,
    notice: String,
    localGovermentUnit: String,
    localGovermentUnitOnly: Boolean,
    bankText1: String,
    bankText2: String,
    bankText3: String,
    attachmentText: String,
    attachmentText2: String
  },

  components: {
    'pdf-header': PdfHeader,
    'pdf-signs': PdfSigns
  },

  computed: {
    renderGovermentOnly () {
      return !this.localGovermentUnitOnly
    },

    getModel () {
      return this.$store.getters['user/userModel']
    }
  },

  methods: {
    getInputValue (property) {
      return this.getModel[property]
    },

    updateModel (property, value, dotsToComma) {
      if(dotsToComma) {
        value = value.replace('.', ',')
      }

      let model = this.$store.getters['user/userModel']
      model[property] = value

      this.$store.dispatch('user/updateModel', model)
    }

  }
}
</script>