<template>
  <div>
    <div class="page-last">
      <p class="page-last__title">
        {{ content.text }}
        <input
          type="text"
          :value="formatValue(getModel['expensesSummary'][formNumber])"
          disabled="disabled"
        > zł
      </p>

      <p class="page-last__user-input">
        Łączna liczba uczniów objętych dotacją celową
        <input
          type="text"
          :value="formatValue(getModel['studentsSummary'][formNumber])"
          @input="updateModel('studentsSummary', $event.target.value, $event)"
        >
      </p>

      <p class="page-last__user-input">
        Łączna liczba szkół objętych dotacją celową
        <input
          type="text"
          :value="formatValue(getModel['schoolsSummary'][formNumber])"
          @input="updateModel('schoolsSummary', $event.target.value, $event)"
        >
      </p>
    </div>

    <pdf-signs
      :extended="isSignExtended"
      :pdfContent="pdfContent"
    />
  </div>
</template>


<script>
import PdfSigns from '../PdfSigns'
import tableMixin from '../../../mixins/tableMixin'

export default {
  name: 'LastPage',

  data: function () {
    return {
      inputRows: []
    }
  },

  components: {
    'pdf-signs': PdfSigns
  },

  props: {
    pdfContent: Object,
    content: Object,
    formNumber: Number
  },

  mixins: [
    tableMixin
  ],

  mounted: function () {
    let tablesSum = this.$store.getters.tableSum[this.formNumber]
    let sum = 0

    if(tablesSum) {
      tablesSum.forEach(value => {
        if(value && !isNaN(parseFloat(value))) {
          sum += parseFloat(value.replace(',', '.'))
        }
      })

      this.updateModel('expensesSummary', sum.toFixed(2).replace('.', ','))
      this.$forceUpdate()
    }
  },

  computed: {
    isSignExtended () {
      return this.content.signExtended || this.pdfContent?.signExtended;

    },

    getModel () {
      return this.$store.getters['user/userModel']
    }
  },

  created: function () {
    this.inputRows = this.content.inputs
  },

  methods: {
    updateModel (property, value, event) {
      if(event) {
        event.target.value = event.target.value.replace('.', ',')
      }
      let model = this.getModel
      model[property][this.formNumber] = value

      this.$store.commit('user/updateModel', model)
    }
  }
}
</script>