<template>
    <v-container class="page">
      <v-row>
        <v-col>
          <h1>Deklaracja dostępności aplikacji </h1>
          <div class="text-left">
            <p>Ministerstwo Edukacji zobowiązuje się zapewnić dostępność aplikacji dotacjepodreczniki.mein.gov.pl zgodnie z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych. Oświadczenie w sprawie dostępności ma zastosowanie do serwisu internetowego:  <a href="https://dotacjepodreczniki.mein.gov.pl/" target="_blank">https://dotacjepodreczniki.mein.gov.pl/</a>. </p>
            <p>Strona internetowa jest częściowo zgodna z ustawą o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.</p>
            <p>Data publikacji strony internetowej:  2021.04.01</p>
            <p>Data ostatniej dużej aktualizacji:  2021.04.12</p>
            <p>Oświadczenie sporządzono dnia: 2021.05.25</p>
            <p>Oświadczenie zaktualizowano dnia: 2021.05.25</p>
            <p>Deklarację sporządzono na podstawie samooceny przeprowadzonej przez podmiot publiczny.</p>
            <h2>Informacje zwrotne i dane kontaktowe</h2>
            <p>W przypadku problemów z dostępnością strony internetowej prosimy o kontakt. Osobą odpowiedzialną jest Pani Małgorzata Tracz adres poczty elektronicznej <a href="mailto:Malgorzata.Tracz@mein.gov.pl">Malgorzata.Tracz@mein.gov.pl</a>. Kontaktować można się także dzwoniąc na numer telefonu 22 34 74 390. Tą samą drogą można składać wnioski o udostępnienie informacji niedostępnej oraz składać skargi na brak zapewnienia dostępności.</p>
            <p>Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji w formach alternatywnych, na przykład odczytanie niedostępnego cyfrowo dokumentu, opisania zawartości filmu bez audiodeskrypcji itp. Żądanie powinno zawierać dane osoby zgłaszającej żądanie, wskazanie, o którą stronę internetową lub aplikację mobilną chodzi oraz sposób kontaktu. Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji w formie alternatywnej, powinna także określić formę tej informacji.</p>
            <p>Podmiot publiczny powinien zrealizować żądanie niezwłocznie i nie później, niż w ciągu 7 dni. Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot publiczny niezwłocznie informuje o tym, kiedy realizacja żądania będzie możliwa, przy czym termin ten nie może być dłuższy niż 2 miesiące. Jeżeli zapewnienie dostępności nie jest możliwe, podmiot publiczny może zaproponować alternatywny sposób dostępu do informacji.</p>
            <p>W przypadku, gdy podmiot odmówi realizacji żądania zapewnienia dostępności lub alternatywnego dostępu do informacji, można złożyć skargę na takie działanie.</p>
            <p>Po wyczerpaniu wszystkich możliwości skargę można przesłać także do Rzecznika Praw Obywatelskich.</p>
            <p><strong>Dane teleadresowe siedziby Ministerstwa Edukacji</strong></p>
            <p>tel. 22 34 74 100<br>
            fax. 22 34 74 261<br>
            e-mail: <a href="mailto:informacja@mein.gov.pl">informacja@mein.gov.pl</a><br>
            ul. Szucha 25<br>
            00-918 Warszawa</p>
            <p>Na stronie internetowej można korzystać ze standardowych skrótów klawiaturowych.</p>
            
            <h2>INFORMACJA O DOSTĘPNOŚCI ARCHITEKTONICZNEJ</h2>
            <p><strong>Dostępność architektoniczna - al. J.Ch. Szucha 25</strong></p>
            <p>Wchodząc do budynku niepełnosprawny ma możliwość skontaktowania się z pracownikiem ochrony za pomocą domofonów zlokalizowanych w wejściu głównym oraz przed bramą nr I.</p>
            <p>Wyżej wymieniony pracownik uda się na miejsce wezwania i w razie potrzeby udzieli niezbędnej pomocy np.:</p>
            <ol>
              <li>opuści słupek blokujący wjazd na dziedziniec a następnie wskaże miejsce do zaparkowania samochodu lub innego pojazdu,</li>
              <li>odprowadzi interesanta do windy i pomoże do niej wjechać przy użyciu rampy,</li>
              <li>pomoże wjechać na schody przy użyciu schodołaza.</li>
            </ol>
            <p>Informujemy ponadto, że na poziomie 0 budynku Ministerstwa znajduje się toaleta przystosowana dla osób niepełnosprawnych ruchowo.</p>
            <p>Do budynku i wszystkich jego pomieszczeń można wejść z psem asystującym i psem przewodnikiem.</p>
            <p>W budynku nie ma oznaczeń w alfabecie brajla ani oznaczeń kontrastowych lub w druku powiększonym dla osób niewidomych i słabowidzących.</p>

            <p><strong>Kontakt dla osób doświadczających trwale lub okresowo trudności w komunikowaniu się</strong></p>
            <p>Zgodnie z przepisami ustawy z dnia 19 sierpnia 2011 r. o języku migowym i innych środkach komunikowania się (Dz. U. z 2023 r. poz. 20 t.j.), Ministerstwo Edukacji udostępnia osobom doświadczającym trwale lub okresowo trudności w komunikowaniu się następujące formy kontaktu z urzędem:</p>
            <p>SMS: +48 601 673 461<br>
            Fax: 22 34 74 261<br>
            E-mail: <a href="mailto:pomoc@mein.gov.pl">pomoc@mein.gov.pl</a><br>
            Skype: Ministerstwo Edukacji i Nauki</p>
            <p>Zapewniamy również dostęp do usług tłumacza PJM, SJM i SKOGN. Uprzejmie prosimy o zgłaszanie chęci skorzystania z takiej usługi, co najmniej na 3 dni robocze przed planowaną wizytą w urzędzie.</p>
            <p>Ministerstwo Edukacji udostępnia dokumenty niezbędne do załatwienia sprawy, w formie dostępnej dla osób uprawnionych, na ich wniosek. Osoba uprawniona ma również prawo do skorzystania z pomocy osoby przybranej w kontaktach z ministerstwem.</p>

            <h2>INFORMACJA DODATKOWA</h2>
            <ul>
              <li>Zadbaliśmy o odpowiedni kontrast tekstu do tła. Dzięki temu materiały opublikowane w aplikacji są czytelne także dla osób słabiej widzących.</li>
              <li>Staramy się, aby zamieszczone w aplikacji informacje był napisane zgodnie z zasadami prostego języka, z zachowaniem odpowiedniego formatowania.</li>
              <li>Materiały wideo uzupełnione są o napisy.</li>
              <li>W miarę możliwości zdjęcia i grafiki uzupełniane są na bieżąco tekstem alternatywnym. Obrazki czysto dekoracyjne zawierają pusty atrybut alt.</li>
              <li>Poruszanie się po aplikacji jest możliwe za pomocą klawisza Tab klawiatury.</li>
              <li>Dotarcie z klawiatury do obszaru głównego aplikacji jest możliwe poprzez Skip linki.</li>
              <li>Elementy interaktywne (takie jak odnośniki czy przyciski) posiadają wyraźne obramowanie (fokus).</li>
              <li>Linki w obrębie serwisu otwierają się w tym samym oknie.</li>
              <li>Linki do serwisów zewnętrznych otwierają się w nowym oknie.</li>
              <li>Formularze są odpowiednio zbudowane i opisane.</li>
              <li>Materiały znajdujące się w aplikacji można przeglądać na ekranach urządzeń mobilnych.</li>
              <li>Aplikacja w dużym stopniu spełnia wytyczne techniczne związane z dostępnością. Pomimo naszych starań może się jednak zdarzyć, że nie zawsze udało nam się to osiągnąć.</li>
              <li>Informacje zamieszczone w aplikacji są na bieżąco przeglądane i aktualizowane.</li>
            </ul>






          </div>

        </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'Availability',

}
</script>
