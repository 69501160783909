<template>
<div>
  <p class="table-section__title">
    {{ content.text }}
  </p>

  <div class="input-section input-section--default">
    <div class="input-section__text input-section__text--additional">
      <p>
        {{ content.subtext }}
        <input
          type="text"
          :value="getModel['expensesSummary'][formNumber]"
          @input="updateModel('expensesSummary', $event.target.value, $event)"
        > zł, z tego:
      </p>
    </div>
    <ul class="input-section__list">
        <li>
          wydatki bieżące
          <input
            type="text"
            :value="getModel['expensesCurrent'][formNumber]"
            @input="updateModel('expensesCurrent', $event.target.value, $event)"
          > zł
        </li>
        <li>
          wydatki majątkowe
          <input
            type="text"
            :value="getModel['expensesFinancial'][formNumber]"
            @input="updateModel('expensesFinancial', $event.target.value, $event)"
          > zł
        </li>
      </ul>
  </div>

  <pdf-signs
    :pdfContent="pdfContent"
    :extended="content.signExtended || pdfContent?.signExtended"
  />

</div>
</template>


<script>
import PdfSigns from  '../PdfSigns'

export default {
  name: 'PageLast',

  props: {
    content: Object,
    pdfContent: Object,
    formNumber: Number
  },

  components: {
    'pdf-signs': PdfSigns
  },

  mounted: function () {
    let tablesSum = this.$store.getters.tableSum[this.formNumber]
    let sum = 0

    if(tablesSum) {
      tablesSum.forEach(value => {
        if(value && !isNaN(parseFloat(value))) {
          sum += parseFloat(value.replace(',', '.'))
        }
      })

      this.updateModel('expensesSummary', sum.toFixed(2).replace('.', ','))
      this.$forceUpdate()
    }

    console.log(this);
  },

  computed: {
    getModel () {
      return this.$store.getters['user/userModel']
    }
  },

  methods: {
    updateModel (property, value, event) {
      if(event) {
        event.target.value = event.target.value.replace('.', ',')
      }

      let model = this.getModel
      model[property][this.formNumber] = value

      this.$store.commit('user/updateModel', model) 
    }
  }
}
</script>