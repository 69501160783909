<template>
    <v-container class="page">
      <v-row>
        <v-col>
          <h1>Obowiązujące akty prawne</h1>
          <div class="text-left">
            <p><a href="/files/Dz.-U.-z-2021-r.-poz.-577.pdf" target="_blank">Rozporządzenie Ministra Edukacji i Nauki z dnia 24 marca 2021 r. w sprawie udzielania dotacji celowej na wyposażenie szkół w podręczniki, materiały edukacyjne i materiały ćwiczeniowe w 2021 r. (Dz. U. z 2021 r. poz. 577);</a></p>
            <p><a href="/files/Dz.-U.-z-2021-r.-poz.-739.pdf" target="_blank">Rozporządzenie Ministra Edukacji i Nauki z dnia 20 kwietnia 2021 r. zmieniające rozporządzenie w sprawie udzielania dotacji celowej na wyposażenie szkół w podręczniki, materiały edukacyjne i materiały ćwiczeniowe w 2021 r. (Dz. U. z 2021 r. poz. 739).</a></p>
          </div>

        </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'Files',

}
</script>
