const state = () => ({
  // Pdf current page
  // -1 is the start page, from 0 we start real pages
  pdfPageIndex: -1,
  pdfContent: null,
  formNumber: 1
})

const mutations = {
  setPdfContent (state, content) {
    state.pdfContent = content
  },

  setPdfPageIndex (state, index) {
    state.pdfPageIndex = index
  },

  setFormNumber (state, number) {
    state.formNumber = number
  }
}

const actions = {
  setPdfContent ({commit}, content) {
    commit('setPdfContent', content)
  },

  setPdfPageIndex ({commit}, index) {
    commit('setPdfPageIndex', index)
  },

  setFormNumber ({commit}, number) {
    commit('setFormNumber', number)
  }
}

const getters = {
  pdfContent: state => state.pdfContent,
  pageIndex: state => state.pdfPageIndex,
  formNumber: state => state.formNumber
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}