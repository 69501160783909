<template>
  <v-container>
    <v-container
      class="intro text-left"
    >
      <v-row>
        <v-col>
          <h2>Dotacja celowa na wyposażenie szkół w podręczniki, materiały edukacyjne i materiały ćwiczeniowe w 2024 roku</h2>
          <p>W dniu 20 marca 2024 roku zostało opublikowane rozporządzenie Ministra Edukacji z dnia 28 lutego 2024 roku w sprawie dotacji celowej na wyposażenie szkół w podręczniki, materiały edukacyjne i materiały ćwiczeniowe w 2024 r. (Dz. U. z 2024 r., poz. 413).</p>
          <p>Poniżej znajdują się załączniki do wypełnienia i wygenerowania w formie PDF:</p>
        </v-col>
      </v-row>
    </v-container>

    <v-row
      class="home"
      v-if="loaded"
    >
      <v-col>
        <v-card
          class="mx-auto"
          max-width="300"
          tile
        >
          <v-list>
            <v-subheader>LISTA ZAŁĄCZNIKÓW</v-subheader>
            <v-list-item-group
              color="primary lighten-1"
            >
              <v-list-item
                v-for="(index) in [0,1,2,3,4,5,6,7,8]"
                :key="index"
                :disabled="index >= 9"
                @click="setPdfContent(index)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-form-select</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                      class="text-left"
                  >
                    {{ getRouteTitle(index) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

     <v-row>
        <v-col>
          <div class="btn-files-container">
            <h2>Opcje zapisu formularzy</h2>
            <v-btn
              elevation="2"
              color="primary"
              @click="saveToFile()"
            >
              Zapisz dane formularzy do pliku
            </v-btn>
            <v-file-input
              label="Wczytaj dane z pliku"
              @change="readFromFile"
              class="input-import"
            ></v-file-input>
          </div>
        </v-col>
     </v-row>

  </v-container>
</template>

<script>
import PdfData from '../Json/pdfObject.json'
import axios from 'axios'

export default {
  name: 'BodyComponent',
  data: function () {
    return {
      loaded: false,
      documentName: 'zalacznik-',
      documentTitle: 'załącznik ',

      pdfMainContent: {},
      pdfStartPage: -1
    }
  },

  created: function () {
    //http://cie-formularze-cms.clearmedia.pl
    //http://127.0.0.1:8001
    //https://dotacjepodreczniki-panel.mein.gov.pl/
    axios.get(process.env.VUE_APP_FORM_JSON_URL, {
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response)
        if(response.data && response.data.Pdfs) {
          this.pdfMainContent = response.data.Pdfs
        } else {
          this.pdfMainContent = PdfData.Pdfs
          this.$store.dispatch('pdf/setPdfPageIndex', this.pdfStartPage)
        }

        this.loaded = true
      })
  },

  methods: {
    setPdfContent (index) {
      this.$router.push(this.getRouteName(index), {pdfContent: this.getPdfData(index) })
      let content = this.pdfMainContent[index].PdfContent
      this.$store.dispatch('pdf/setPdfContent', content)
      this.$store.dispatch('pdf/setPdfPageIndex', this.pdfStartPage)
      this.$store.dispatch('pdf/setFormNumber', index)
    },

    getPdfData (index) {
      return this.pdfMainContent[index].PdfContent
    },

    getRouteName (index) {
      return `${this.documentName}${index+1}`
    },

    getRouteTitle (index) {
      return ((index >= 9) ? '' : 'Wypełnij ') + `${this.documentTitle} nr ${index+1}`
    },

    saveToFile () {

      let userModel = this.$store.getters['user/userModel']
      userModel['afterTableInputRow'] = Object.assign({}, userModel['afterTableInputRow'])

      var storeData = {
        tableData: Object.assign({}, this.$store.getters.tableData),
        tableSum: Object.assign({}, this.$store.getters.tableSum),
        tableSchoolType: this.$store.getters.tableSchoolType,
        tableSchools: Object.assign({}, this.$store.getters.tableSchools),
        userModel: Object.assign({}, this.$store.getters['user/userModel'])
      }

      console.log(storeData)
      // console.log(Object.assign({}, this.$store.getters.tableData))


      const data = JSON.stringify(storeData)
      const blob = new Blob([data], {type: 'text/plain'})
      const e = document.createEvent('MouseEvents'),
      link = document.createElement('a');
      link.download = "data.json"
      link.href = window.URL.createObjectURL(blob)
      link.dataset.downloadurl = ['text/json', link.download, link.href].join(':')
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      link.dispatchEvent(e)
    },

    readFromFile (event) {
      var reader = new FileReader()
      reader.readAsText(event);
      reader.onload = () => {
        let data = JSON.parse(reader.result)
        if(data.tableData) {
          this.$store.commit('updateTableData', data.tableData)
        }

        if(data.tableSum) {
          this.$store.commit('updateAllTableSum', data.tableSum)
        }

        if(data.tableSchoolType) {
          this.$store.commit('updateAllTableSchoolType', data.tableSchoolType)
        }

        if(data.tableSchools) {
          this.$store.commit('updateAllTableSchools', data.tableSchools)
        }

        if(data.userModel) {
          this.$store.commit('user/updateModel', data.userModel)
        }

        alert('Dane zostały wczytane!')
      };
    }

  }
}
</script>
