<template>
  <div class="footnote">
    <div class="footnote__line" v-if="(isNested || olNested.length > 0 || olNotNested.length > 0)"></div>
    <div>
      <ul
        v-if="isNested"
        :start="footnoteContent.start"
        class="footnote__nested"
      >
        <li v-if="isFirstLi" :class="liFirstClass">
          {{ footnoteContent.liFirst }}

          <ol
            v-if="hasOl"
          >
            <li v-for="(item, index) in olNested" :key="index">
              {{ item.text }}

              <ol
                v-if="hasOlChilds(index)"
                type="a"
              >
                <li v-for="(item, index) in getOlChilds(index)" :key="index">
                  {{ item }}
                </li>
              </ol>
            </li>
          </ol>
        </li>


        <li v-else>
          {{ footnoteContent.firstLine }}
          <ol>
            <li>elektronicznej opatrzonego kwalifikowanym podpisem elektronicznym, podpisem osobistym lub podpisem zaufanym umieszcza się ten podpis;</li>
            <li>
              papierowej i elektronicznej:
              <ol type="a">
                <li>we wniosku w postaci papierowej umieszcza się pieczęć i podpis dyrektora szkoły,</li>
                <li>we wniosku w postaci elektronicznej nie umieszcza się pieczęci i podpisu dyrektora szkoły.</li>
              </ol>
            </li>
          </ol>
        </li>
      </ul>

      <ol
        v-else
        class="footnote__not-nested"
        :start="footnoteContent.start"
      >
        <li style="white-space: pre-line;" v-for="(item, index) in olNotNested" :key="index" v-html="item.text"></li>
      </ol>
    </div>

  </div>
</template>


<script>
export default {
  name: 'PdfFootnote',

  data: function () {
    return {
      isNested: Boolean,
      liFirst: '',
      liFirstClass: '',
      olNested: [],
      olNotNested: []
    }
  },

  props: {
    footnoteContent: {
      type: Object,
      required: true
    }
  },
  watch: {
    footnoteContent: function () {
      this.initializeData()
    }
  },

  created: function () {
    this.initializeData()
  },

  computed: {
    isFirstLi () {
      return this.liFirst && this.liFirst.length > 0
        ? true
        : false
    },

    hasOl () {
      return this.olNested.length > 0
        ? true
        : false
    },
  },

  methods: {
    initializeData () {
      this.isNested = this.footnoteContent.isNested
      this.liFirst = this.footnoteContent.liFirst
      this.liFirstClass = this.footnoteContent.liFirstClass
      this.olNested = this.footnoteContent.olNested
      this.olNotNested = this.footnoteContent.olNotNested
    },

    getOl (index) {
      return this.olNested[index].items
    },

    hasOlChilds (index) {
      return this.olNested[index].childs.length > 0
        ? true
        : false
    },

    getOlChilds (index) {
      return this.olNested[index].childs
    }
  }
}
</script>