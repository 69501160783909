import Vue from 'vue'
import Vuex from 'vuex'
import pdf from './modules/pdf'
import user from './modules/user'
import jsPdf from './modules/jsPdf'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
  plugins: [createPersistedState()],

  modules: {
    pdf,
    user,
    jsPdf
  },

  state: {
    tableData: [],
    tableSum: [],
    tableSchoolType: [],
    tableSchools: {
      '8': [true],
      '9': [true]
    }
  },

  mutations: {
    updateTableData (state, content) {
      state.tableData = content
    },

    updateTableSum (state, content) {
      let tmp = state.tableSum[content.id] ? state.tableSum[content.id] : []
      tmp[content.page] = content.value
      state.tableSum[content.id] = tmp
    },

    updateAllTableSum (state, content) {
      state.tableSum = content
    },

    updateTableSchoolType (state, content) {
      state.tableSchoolType[content.id] = content.value
    },

    updateAllTableSchoolType (state, content) {
      state.tableSchoolType = content
    },

    updateAllTableSchools (state, content) {
      state.tableSchools = content
    },

    resetModel (state) {
      state.tableData = []
      state.tableSum = []
      state.tableSchoolType = []
      state.tableSchools = {
        '8': [true],
        '9': [true]
      }
    },
  },

  actions: {
  },

  getters: {
    tableData: state => state.tableData,
    tableSum: state => state.tableSum,
    tableSchoolType: state => state.tableSchoolType,
    tableSchools: state => state.tableSchools
  }
})
