<template>
  <div>
    <pdf-intro
      :pdfPageIndex="0"
      :pdfPagesLength="1"
      :formNumber="3"
      form="Formularz 3"
    />

    <page-one
      :attachmentName="content.attachmentName"
      :attachmentNumber="content.attachmentNumber"
      :pageTitle="content.pageTitle"
      :mainTitle="content.mainTitle"

      :instituteName="content.instituteName"
      :notice="content.notice"

      :bankText1="content.bankText1"
      :bankText2="content.bankText2"
      :bankText3="content.bankText3"
      :attachmentText="content.attachmentText"
      :attachmentText2="content.attachmentText2"

      :localGovermentUnit="content.localGovermentUnit"
      :localGovermentUnitOnly="content.localGovermentUnitOnly"
      :bottomInfoText="content.bottomInfoText"
    />

    <pdf-footnote
      :footnoteContent="content.footnote"
    />

    <page-switcher
      :pdfPageIndex="pdfPageIndex"
      :pdfPagesLength="0"
      @generatePdf="generatePdf"
    />

  </div>
</template>

<script>
import PageOne from './Pdf-3/PageOne'
import PdfIntro from '../Shared/PdfIntro'
import jsPdfMixin from '../../mixins/jsPdfMixin'
import PdfFootnote from '../Shared/PdfFootnote'
import PdfPageSwitcher from '../Shared/PdfPageSwitch'


export default {
  name: 'Pdf3',

  data: function () {
    return {
      content: {},
      pdfPageIndex: -1
    }
  },

  mixins: [jsPdfMixin],

  components: {
    'page-one': PageOne,
    'pdf-intro': PdfIntro,
    'page-switcher': PdfPageSwitcher,
    'pdf-footnote': PdfFootnote
  },

  props: ['pdfContent'],

  created: function () {
    this.content = this.$store.getters['pdf/pdfContent']

    if (this.content == null || this.content.attachmentNumber != 'Załącznik nr 3') {
      this.content = this.pdfContent
    }
  },

  methods: {
    generatePdf () {
      this.initializeDoc()
      this.createFirstPage()
      this.savePdfPage()
    }
  }
}
</script>