export default {
  data: function() {
    return {
      }
  },

  methods: {

    formatValue(value) {
      if(value) {
        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
      return value; //.toString()
    },

  }
}