<template>
  <header id="header-component">
    <v-container
      fluid
    >
      <v-row>
        <v-col
          class="logos"
          md="4"
        >
          <a
            href="https://www.gov.pl/web/edukacja-i-nauka"
            target="_blank"
          >
            <img
              src="@/assets/images/new_mein_logo.svg"
              alt="logo mein"
            >
          </a>
          <a
            href="https://icein.gov.pl/"
            target="_blank"
          >
            <img
              src="@/assets/images/new_icein_logo.svg"
              alt="logo mein"
            >
          </a>
        </v-col>
        <v-col
          class="menu"
          md="8"
        >
          <v-btn
            color="primary"
            text
            rounded
            class="my-2"
            to="/"
          >
          Strona główna
          </v-btn>
          <v-btn
            color="primary"
            text
            rounded
            class="my-2"
            to="/polityka-prywatnosci"
          >
          Polityka prywatności
          </v-btn>
          <v-btn
            color="primary"
            text
            rounded
            class="my-2"
            to="/dostepnosc"
          >
          Deklaracja dostępności
          </v-btn>
<!--          <v-btn-->
<!--            color="primary"-->
<!--            text-->
<!--            rounded-->
<!--            class="my-2"-->
<!--            to="/pliki"-->
<!--          >-->
<!--          OBOWIĄZUJĄCE AKTY PRAWNE-->
<!--          </v-btn>-->
          <v-btn
            color="primary"
            text
            rounded
            class="my-2"
            to="/reset"
          >
          Wyczyść dane
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-container>
  </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>
