<template>
    <body-component />
</template>

<script>
import BodyComponent from '../components/Body'
export default {
  name: 'Home',

  components: {
    'body-component': BodyComponent
  }
}
</script>
