<template>
  <div class="signs">
    <div class="signs__label">
      <input type="date" id="start" name="trip-start"
       :value="this.$store.getters['user/userModel']['pdfDate']"
       @input="updateModel('pdfDate', $event.target.value)">
      <label>(data sporządzenia)</label>
    </div>

    <div
      v-if="!extended"
      class="signs__label"
    >
      <input type="text">
      <label v-if="['Załącznik nr 2', 'Załącznik nr 7'].includes(pdfContent?.attachmentNumber)">(pieczęć i podpis dyrektora szkoły**)</label>
      <label v-else>(pieczęć i podpis dyrektora szkoły*)</label>
    </div>

    <div
      v-else
      class="signs__label"
    >
      <input type="text">
      <label v-if="['Załącznik nr 5', 'Załącznik nr 9'].includes(pdfContent?.attachmentNumber)">pieczęć i podpis<br />wójta/ burmistrza/ prezydenta miasta/ starosty/ marszałka województwa **)</label>
      <label v-else>pieczęć i podpis<br />wójta/ burmistrza/ prezydenta miasta/ starosty/ marszałka województwa *)</label>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PdfSigns',

  props: {
    pdfContent: Object,
    extended: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getDate () {
      return this.$store.getters['user/userModel']['pdfDate']
    }
  },

  methods: {
    updateModel (property, value) {
      let model = this.$store.getters['user/userModel']
      model[property] = value

      this.$store.commit('user/updateModel', model)
    }
  }
}
</script>