<template>
  <v-container>
    <v-row>
      <v-col>
        <v-alert
          type="success"
        >
          Dane zostały wyczyszczone
        </v-alert>
        <v-btn
          elevation="2"
          color="primary"
          to="/"
        >
          Powrót na stronę główną
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',

  created: function () {
    this.$store.commit('user/resetModel')
    this.$store.commit('resetModel')
    localStorage.removeItem('vuex')

    window.setTimeout(function() {
      window.location.href = window.location.origin
    }, 1000);
    

  }
}
</script>
