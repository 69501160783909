<template>
    <v-app>
      <header-component />

      <v-container class="main-container">
        <v-row>
          <v-col>
            <router-view />
          </v-col>
        </v-row>
      </v-container>

      <footer-component />
    </v-app>

<!--  <div style="text-align: center; padding: 100px 15px;">-->
<!--    <div style="font-size: 40px; font-weight: 700; margin-bottom: 20px;">Przerwa techniczna</div>-->
<!--    <div style="font-size: 24px; font-weight: 600; margin-bottom: 12px;">Szanowni Użytkownicy,</div>-->
<!--    <div>Informujemy, że w związku z pracami aktualizacyjnymi formularzy na naszej stronie, będzie ona tymczasowo niedostępna.</div>-->
<!--  </div>-->
</template>


<script>
import HeaderComponent from './components/Header'
import FooterComponent from './components/Footer'

export default {
  name: 'App',

  components: {
    'header-component': HeaderComponent,
    'footer-component': FooterComponent
  },

  metaInfo() {
    return { 
      title: "Dotacja celowa na podręczniki, materiały edukacyjne i materiały ćwiczeniowe - formularze",
      meta: [
          { name: 'description', content:  'W dniu 30 marca 2021 roku zostało opublikowane rozporządzenie Ministra Edukacji i Nauki z dnia 24 marca 2021 roku w sprawie udzielania dotacji celowej na wyposażenie szkół w podręczniki, materiały edukacyjne i materiały ćwiczeniowe w 2021 r. (Dz. U. z 2021 r, poz. 577).'},
          { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
          { property: 'og:site_name', content: 'Epiloge'},
          { property: 'og:type', content: 'website'},
          { name: 'robots', content: 'index,follow'}
      ]
    }
  }
}
</script>

<style lang="scss">
  #app {}
</style>
