<template>
  <div class="first-page">

    <pdf-header
      :attachmentName="attachmentName"
      :attachmentNumber="attachmentNumber"
      :pageTitle="pageTitle"
      :mainTitle="mainTitle"

      :instituteName="instituteName"
      :notice="notice"

      :localGovermentUnit="localGovermentUnit"
      :localGovermentUnitOnly="localGovermentUnitOnly"
    />

    <div class="first-page__content">
      <p class="paragraph__main-title" v-html="mainTitle"></p>

      <div
        v-if="ifCheckboxes"
        class="first-page__checkboxes first-page__checkboxes--pdf-students">
        <p class="first-page__checkboxes__title">Dotyczy uczniów:</p>
        <label v-for="(checkbox, index) in checkboxes" :key="index"
          class="first-page__checkboxes__checkbox">
            <input
              type="checkbox"
              :name="`check-${index+1}`"
              :checked="getStudentCheckbox(index)"
              @change="changeCheckboxes"
            >
            {{ checkbox }}
        </label>
      </div>

      <div class="first-page__checkboxes first-page__checkboxes--pdf-type">
        <div>
          <label class="first-page__checkboxes__checkbox">
            <input type="checkbox" name="firstEdit" @change="changeRadio" :checked="getModelValue('firstEdit')">
            informacja składana po raz pierwszy
          </label>

          <label class="first-page__checkboxes__checkbox">
            <input type="checkbox" name="updateEdit" @change="changeRadio" :checked="getModelValue('updateEdit')">
            aktualizacja informacji
          </label>

          <p class="first-page__checkboxes__notice text-center">
            <strong>(należy zaznaczyć właściwy kwadrat)</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PdfHeader from '../../Shared/PdfHeader'

export default {
  name: 'PageOne',

  props: {
    attachmentNumber: String,
    attachmentName: String,
    pageTitle: String,
    mainTitle: String,
    instituteName: String,
    notice: String,
    localGovermentUnit: String,
    localGovermentUnitOnly: Boolean,
    checkboxes: Array,
  },

  components: {
    'pdf-header': PdfHeader
  },

  computed: {
    renderGovermentOnly () {
      return !this.localGovermentUnitOnly
    },

    ifCheckboxes () {
      return this.checkboxes.length > 0
        ? true
        : false
    },

    getModel () {
      return this.$store.getters['user/userModel']
    }
  },

  methods: {
    getModelValue (property) {
      return this.getModel[property]
    },

    changeRadio (event) {
      let model = this.$store.getters['user/userModel']

      let currentCheckbox = event.target
      let checkboxes = document.querySelectorAll(".first-page__checkboxes--pdf-type input")
      checkboxes.forEach(checkbox => {
        if(checkbox != currentCheckbox) {
          checkbox.checked = false

          model[checkbox.name] = checkbox.checked
          this.$store.commit('user/updateModel', model)
        }
      })

      model[currentCheckbox.name] = currentCheckbox.checked
      this.$store.commit('user/updateModel', model)
    },

    changeCheckboxes () {
      let model = this.$store.getters['user/userModel']
      let checkboxes = document.querySelectorAll('.first-page__checkboxes--pdf-students input')

      let index = 0
      let currentCheckbox = event.target
      checkboxes.forEach(checkbox => {
        if(checkbox != currentCheckbox) {
          checkbox.checked = false
        }
        let checkName = checkbox.name
        model.studentCheckboxes[index++][checkName] = checkbox.checked
      })

      this.$store.commit('user/updateModel', model)
    },

    getStudentCheckbox (index) {
      return this.getModelValue('studentCheckboxes')[index][`check-${index+1}`]
    }
  }
}
</script>