<template>
  <div>
    <page-table
      v-for="(content, index) in tables" :key="index"
      :tableContent="content"
      v-bind:page="page"
      :formNumber="formNumber"
      :tableNumber="index"
    />
  </div>
</template>

<script>
import PageTable from './Table'

export default {
  name: 'PageShared',

  data: function () {
    return {
      tables: []
    }
  },

  created: function () {
    this.updatePageData()
  },

  props: {
    content: Object,
    page: Number,
    formNumber: Number
  },

  components: {
    'page-table': PageTable
  },

  watch: {
    content: function () {
      this.updatePageData()
    }
  },

  methods: {
    updatePageData () {
      this.tables = this.content.tables
    }
  }
}
</script>