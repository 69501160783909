<template>
    <v-container class="page">
      <v-row>
        <v-col>
          <h1>Polityka prywatności</h1>
          <div class="text-left">
            <p>Ministerstwo Edukacji szanuje prywatność użytkowników korzystających z zasobów znajdujących się w aplikacji  <a href="https://dotacjepodreczniki.mein.gov.pl/" target="_blank">https://dotacjepodreczniki.mein.gov.pl/</a>. </p>
            <p>Aby dowiedzieć się więcej na temat ochrony danych użytkowników, prosimy o zapoznanie się z informacjami dotyczącymi naszej polityki prywatności.</p>
            <ol>
              <li>Korzystając z aplikacji <a href="https://dotacjepodreczniki.mein.gov.pl/" target="_blank">https://dotacjepodreczniki.mein.gov.pl/</a> akceptujesz zasady polityki prywatności.</li>
              <li>Wśród informacji uzyskiwanych podczas korzystania z serwisu mogą być:
                <ul>
                  <li>Informacje w dziennikach serwerów - nasze serwery automatycznie zapisują takie dane, jak żądanie strony wysyłane przez użytkownika, datę i godzinę żądania, typ przeglądarki, język przeglądarki, typ systemu operacyjnego, adres IP (każdy komputer podłączony do Internetu ma przypisany niepowtarzalny numer, czyli adres IP).</li>
                  <li>Pliki cookies (tzw. ciasteczka) - niewielkie pliki tekstowe wysyłane przez serwis internetowy, który odwiedza internauta, do urządzenia internauty (komputer, smartfon itp.).</li>
                  <li>Używamy ciasteczek, aby:
                    <ul>
                      <li>ułatwić użytkownikom korzystanie z witryny - pliki cookies rozpoznają urządzenie użytkownika i odpowiednio wyświetlają stronę internetową, dostosowaną do jego indywidualnych potrzeb,</li>    
                      <li>tworzyć anonimowe statystyki serwisu - dzięki temu lepiej poznajemy oczekiwania naszych użytkowników i rozwijamy serwis tak, aby ułatwić dotarcie do najczęściej poszukiwanych informacji (np. analizując zapytania z wyszukiwarek),</li>
                      <li>prezentować treści multimedialne na stronach internetowych serwisu, które są pobierane z zewnętrznego serwisu internetowego,</li>
                      <li>używamy cookies google-analytics.com, które służą do prowadzenia statystyk dla witryny epodreczniki.pl. Dokładny sposób działania i politykę prywatności Google Analytics można znaleźć pod adresem: <a href="http://www.google.com/analytics/learn/privacy.html" target="_blank">http://www.google.com/analytics/learn/privacy.html</a>.</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ol>
            <p>Przeglądarki internetowe zwykle domyślnie dopuszczają przechowywanie plików cookies na urządzeniu użytkownika. Jednak internauci mogą samodzielnie zarządzać ciasteczkami, np. zablokować je, wybierając w przeglądarce opcję odrzucania ciasteczek. Może to jednak spowodować, że niektóre funkcje aplikacji https://dotacjepodreczniki.mein.gov.pl/  nie będą działać poprawnie.</p>
            <ol>
              <li>Właścicielem aplikacji  <a href="https://dotacjepodreczniki.mein.gov.pl/" target="_blank">https://dotacjepodreczniki.mein.gov.pl/</a> jest Ministerstwo Edukacji z siedzibą w Warszawie, ul. Szucha 25. Wszelkie dane są w nich gromadzone przy zastosowaniu odpowiednich środków ochrony poufności, integralności i bezpieczeństwa informacji.</li>
              <li>Zbierane dane służą do zapewnienia określonych usług naszym użytkownikom oraz do celów administracyjnych i statystycznych.</li>
              <li>Aby skutecznie chronić zbierane dane zabezpieczamy je przed nieuprawnionym dostępem osób trzecich oraz kontrolujemy nasze metody gromadzenia, przechowywania i przetwarzania informacji. Dostępu do danych udzielamy jedynie tym pracownikom i podmiotom, które muszą mieć do nich dostęp, aby je przetwarzać wyłącznie w celach opisanych w polityce prywatności.</li>
              <li>W przypadku zmian w naszej polityce prywatności zostanie ona odpowiednio uaktualniona.</li>
            </ol>
          </div>

        </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'Privacy',

}
</script>
