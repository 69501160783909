<template>
  <div>
    <page-table
      v-for="(content, index) in tables" :key="index"
      :tableContent="content"
      v-bind:page="page"
      :formNumber="formNumber"
      :tableNumber="index"
      @updated="updatePageData()"
    />

    <div class="input-section"
      v-if="content.text">
      <div class="input-section__text">
        <p>
          {{ content.text }}
          <input
            type="text"
            :value="formatValue(getModel['expensesSummary'][formNumber])"
            @input="updateModel('expensesSummary', $event.target.value, $event)"
          > zł
        </p>
      </div>

      <div class="input-section__text input-section__text--additional">
        <p>
          {{ content.subtext }}
          <input
            type="text"
            :value="formatValue(getModel['studentsSummary'][formNumber])"
            @input="updateModel('studentsSummary', $event.target.value, $event)"
          >
        </p>
      </div>
    </div>
  </div>

</template>

<script>
import tableMixin from '../../../mixins/tableMixin'
import PageTable from './Table'

export default {
  name: 'PageShared',

  data: function () {
    return {
      tables: []
    }
  },

  created: function () {
    this.updatePageData()
  },

  props: {
    content: Object,
    page: Number,
    formNumber: Number
  },

  components: {
    'page-table': PageTable,
  },

  mixins: [
    tableMixin
  ],

  watch: {
    content: function () {
      this.updatePageData()
    }
  },

  computed: {
    getModel () {
      return this.$store.getters['user/userModel']
    }
  },

  methods: {
    updatePageData () {
      this.tables = this.content.tables

      let tablesSum = this.$store.getters.tableSum[this.formNumber]
      let sum = 0

      if(tablesSum) {
        tablesSum.forEach(value => {
          if(value && !isNaN(parseFloat(value))) {
            sum += parseFloat(value.toFixed(2).replace(',', '.'))
          }
        })

        this.updateModel('expensesSummary', sum.toFixed(2).replace('.', ','))
      }
      
      this.$forceUpdate()
    },

    updateModel (property, value, event) {
      if(event) {
        event.target.value = event.target.value.replace('.', ',')
      }

      let model = this.getModel
      model[property][this.formNumber] = value

      this.$store.commit('user/updateModel', model) 
    }
  }
}
</script>