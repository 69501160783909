import { OpenSans, OpenSansBold, OpenSansItalic } from '../assets/jsPdf/Fonts/vfs_fonts'
import 'jspdf-autotable'
import jsPDF from 'jspdf'

export default {
  data: function () {
    return {
      doc: undefined,
      pagesCounter: [],
      lastPageAdded: false,

      // PDF sizes
      sizes: {
        attachment: 262,
        leftSection: 20,
        rightSection: 187,
        centered: 148.5,
        centeredHeight: 105,

        // First page top lines
        lineLeftEnd: 80,
        lineRightEnd: 240,

        // initial for page table above text
        xStart: 16,
        yStart: 17,

        yStartTable: 20,

        footnoteStartX: 16,
        footnoteEndX: 65,

        // Pdf max string length
        maxStringLength: 270,
        maxStringCheckbox: 260,

        pdfWidth: 297,
        pdfHeight: 210,

        // Pdf content start from right, top is 16, margin bottm and left is also 16
        pdfContentEndWidth: 281,
        pdfContentEndHeight: 194,


        footnoteNewLine: 1.5,
        footnoteStartAfterLine: 4,
        // only one line of footnote at bottom
        footnoteAtBottom: 190
      }
    }
  },

  methods: {
    initializeDoc () {
      this.doc = new jsPDF({
        orientation: 'l',
        format: 'a4',
        filters: ['ASCIIHexEncode']
      })

      this.doc.addFileToVFS('./assets/Fonts/OpenSans-Italic.ttf', OpenSansItalic)
      this.doc.addFont('./assets/Fonts/OpenSans-Italic.ttf', 'OpenSans', 'italic')
      this.doc.addFileToVFS('./assets/Fonts/OpenSans-Bold.ttf', OpenSansBold)
      this.doc.addFont('./assets/Fonts/OpenSans-Bold.ttf', 'OpenSans', 'bold')
      this.doc.addFileToVFS('./assets/Fonts/OpenSans-Regular.ttf', OpenSans)
      this.doc.addFont('./assets/Fonts/OpenSans-Regular.ttf', 'OpenSans', 'normal')
      this.doc.setFont('OpenSans')
      this.doc.setFontSize(9)

      this.pagesCounter = []
    },

    createFirstPage () {
      let pdfContent = this.$store.getters['pdf/pdfContent']
      let userModel = this.$store.getters['user/userModel']
      let currentY = 10

      currentY =  this.createStartInfo(this.doc, currentY, pdfContent?.attachmentName)

      this.doc.setFont('OpenSans', 'italic')
      this.doc.text(pdfContent.attachmentNumber, this.sizes.attachment, currentY)
      this.doc.text(pdfContent.pageTitle, this.sizes.centered, 26, { align: 'center' })
      this.doc.setFont('OpenSans', 'normal')

      this.createFirstPageStartFields(pdfContent, userModel)

      // Main section
      this.doc.setFont('OpenSans', 'bold')
      currentY = this.sizes.centeredHeight - 10
      this.doc.setFontSize(10)
      let mainTitleHeight = this.getTextHeight(pdfContent.mainTitle)
      this.doc.text(this.splitWideText(pdfContent.mainTitle), this.sizes.centered, currentY, null, null, 'center')
      this.doc.setFont('OpenSans', 'normal')
      currentY += mainTitleHeight
      currentY += 8

      if (pdfContent.checkboxes && pdfContent.checkboxes.length > 0) {
        currentY = this.createCheckboxesLong(this.doc, pdfContent, currentY)

        if (!pdfContent.checkboxesShort) {
          currentY = this.createCheckboxesDescription(currentY)
        }
      }

      if (pdfContent.checkboxesShort) {
        pdfContent.checkboxes.length > 0 ? currentY : currentY += 20
        currentY = this.createCheckboxesShort(currentY)
        currentY = this.createCheckboxesDescription(currentY)
      }

      if (pdfContent?.attachmentNumber == 'Załącznik nr 3') {
        this.firstPagePdf_3(this.doc, currentY, userModel, pdfContent)
      }

      if (pdfContent.notice && pdfContent.notice.length > 1) {
        this.doc.setFontSize(8)
        this.doc.text(this.splitWideText(pdfContent.notice), this.sizes.centered, currentY += 10, null, null, 'center')
      }

      if (pdfContent.footnote && pdfContent.attachmentNumber != 'Załącznik nr 3') {
        if (pdfContent.footnote.liFirst) {
          this.doc.setFontSize(8)
          this.printFootnoteLine(this.sizes.footnoteAtBottom)
          this.doc.text(`*) ${pdfContent.footnote.liFirst}`, this.sizes.xStart, this.sizes.footnoteStartAfterLine + this.sizes.footnoteAtBottom)
        }

        if (pdfContent.footnote.olNotNested) {
          let nestedFootnotes = pdfContent.footnote.olNotNested

          currentY += 10;

          for (let i = 0; i < nestedFootnotes.length; i++) {
            currentY += 6;
            let text = nestedFootnotes[i].text
            this.doc.text(`${i + 1}) ${text}`, this.sizes.xStart + 4, currentY)
          }
        }
      }

      this.lastPageAdded = false
    },

    // PDF - 3
    firstPagePdf_3 (doc, currentY, userModel, pdfContent) {
      let footnote = pdfContent.footnote

      let value = `${pdfContent.bankText1} ${userModel.value}` //Na podstawie art. 55 ust. 3 ustawy z dnia 27 października 2017 r. o finansowaniu zadań oświatowych (Dz. U. z 2020 r. poz. 2029 i 2400) wnoszę o udzielenie dotacji celowej, o której mowa w art. 55 ust. 4 tej ustawy, na 2021 r. w kwocie:
      value = this.splitWideText(value)
      doc.text(value, this.sizes.xStart, currentY += 2, null, null, 'left')
      doc.text('zł', this.sizes.pdfContentEndWidth, currentY + 4, null, null, 'right')
      currentY += this.getTextHeight(value)
      this.printDashedLine(132, currentY - 2, this.sizes.pdfContentEndWidth, 10)

      doc.setFont('OpenSans', 'italic')
      let valueVerbally = `(słownie: ${userModel.valueVerbally}`
      valueVerbally = this.splitWideText(valueVerbally)
      doc.text(valueVerbally, this.sizes.xStart, currentY += 2, null, null, 'left')
      let valueWidth = this.getTextWidth('(słownie: ')
      doc.text(')', this.sizes.pdfContentEndWidth, currentY, null, null, 'right')
      this.printDashedLine(this.sizes.xStart + valueWidth , currentY += 1, this.sizes.pdfContentEndWidth, 10)
      currentY += this.getTextHeight(valueVerbally)
      doc.setFont('OpenSans', 'normal')

      let bankInfo = `${pdfContent.bankText2} ${userModel.bankInfo}` //Środki proszę przekazać na następujący rachunek bankowy szkoły:
      bankInfo = this.splitWideText(bankInfo)
      doc.text(bankInfo, this.sizes.xStart, currentY += 2, null, null, 'left')
      currentY += this.getTextHeight(bankInfo)

      let bankInfo2 = pdfContent.bankText3 //`(nazwa banku, nr rachunku)`
      let bankInfoX = (this.sizes.xStart + this.getTextWidth('Środki proszę przekazać na następujący rachunek bankowy szkoły: '))
      doc.setFontSize(8)
      doc.text(bankInfo2, bankInfoX + 40, currentY + 1)
      this.printDashedLine(bankInfoX, currentY - 2, this.sizes.pdfContentEndWidth, 10)

      let additionalText = pdfContent.attachmentText //'Do wniosku załączam informacje niezbędne dla ustalenia wysokości dotacji celowej z budżetu jednostki samorządu terytorialnego na wyposażenie szkoły w podręczniki, materiały edukacyjne lub materiały ćwiczeniowe w 2021 r.'
      additionalText = this.splitWideText(additionalText)
      doc.text(additionalText, this.sizes.xStart, currentY += 7, null, null, 'left')
      currentY += this.getTextHeight(additionalText)

      currentY = this.createLastPageSigns(doc, currentY -= 50, userModel.pdfDate)

      doc.setFontSize(8)
      let additionalText_2 = pdfContent.attachmentText2 //'W załączeniu: informacje niezbędne dla ustalenia wysokości dotacji celowej z budżetu jednostki samorządu terytorialnego na wyposażenie szkoły w podręczniki, materiały edukacyjne lub materiały ćwiczeniowe w 2021 r.'
      additionalText_2 = this.splitWideText(additionalText_2)
      doc.text(additionalText_2, this.sizes.xStart, currentY += 10, null, null, 'left')

      this.createFootnote(footnote, doc, currentY - 50 + this.sizes.footnoteStartAfterLine)
    },


    // CHECKBOXES
    createCheckboxesShort (yPosition) {
      let currentY = yPosition + 5
      let xCenterd = this.sizes.centered - 20

      this.doc.text('informacja składana po raz pierwszy', xCenterd, currentY, null, null, 'left')
      this.createPdfCheckboxSquare(this.doc, xCenterd, currentY, this.$store.getters['user/userModel'].firstEdit)

      this.doc.text('aktualizacja informacji', xCenterd, currentY += 8, null, null, 'left')
      this.createPdfCheckboxSquare(this.doc, xCenterd, currentY, this.$store.getters['user/userModel'].updateEdit)

      return currentY
    },

    createCheckboxesLong (doc, pdfContent, yPosition) {
      let checkboxes = pdfContent.checkboxes
      if (checkboxes.length == 0) {
        return
      }

      doc.text('Dotyczy uczniów:', this.sizes.xStart + 10, yPosition, null, null, 'left')
      yPosition += 5
      for (let i = 0; i < checkboxes.length; i++) {
        let checkText = this.splitWideText(checkboxes[i], true)
        doc.text(checkText, this.sizes.xStart + 15, yPosition, null, null, 'left')
        let checkState = this.$store.getters['user/userModel'].studentCheckboxes[i][`check-${i + 1}`]

        if (checkState) {
          this.createPdfCheckboxSquare(doc, this.sizes.xStart + 15, yPosition, true)
        }
        else {
          this.createPdfCheckboxSquare(doc, this.sizes.xStart + 15, yPosition)
        }

        yPosition += doc.getTextDimensions(checkText).h + 0.3
      }

      return yPosition
    },

    createCheckboxesDescription (yPosition) {
      this.doc.setFont('OpenSans', 'bold')
      this.doc.setFontSize(8)
      this.doc.text('(należy zaznaczyć właściwy kwadrat)', this.sizes.centered, yPosition += 6, null, null, 'center' )
      this.doc.setFontSize(10)
      this.doc.setFont('OpenSans', 'normal')

      return yPosition
    },

    createPdfCheckboxSquare (doc, x, y, fill = false) {
      if (fill) {
        doc.setFillColor(0, 0, 0)
        doc.rect(x - 5, y - 2, 2, 2, 'F')
        return
      }

      doc.rect(x - 5, y - 2, 2, 2)
    },


    // FIRST PAGE
    createStartInfo (doc, currentY, attachmentName) {
      let text = attachmentName ? attachmentName : 'Załącznik do rozporządzenia Ministra Edukacji i Nauki z dnia 24 marca 2021 r. (poz. 577)'
      let startX = this.sizes.attachment - 60
      let marginRight = this.sizes.pdfWidth - 14
      let textWidth = marginRight - startX

      text = doc.splitTextToSize(text, textWidth)
      doc.text(text, this.sizes.attachment - 60, currentY)

      return currentY += 10
    },

    createFirstPageStartFields (pdfContent, userModel) {
      let xPosition = this.sizes.leftSection
      let lineEndPosition = this.sizes.lineLeftEnd

      if (!pdfContent.localGovermentUnitOnly) {
        this.doc.text(pdfContent.instituteName, xPosition, 42, {align: 'left'})
        this.doc.text(userModel.instituteName, xPosition, 52, {align: 'left'})
        this.printDashedLine(xPosition, 53, lineEndPosition, 9)

        this.doc.text('Adres', xPosition, 59, {align: 'left'})
        this.doc.text(userModel.address, xPosition, 65, {align: 'left'})
        this.printDashedLine(xPosition, 66, lineEndPosition, 9)

        this.doc.text('REGON', xPosition, 74, {align: 'left'})
        this.doc.text(userModel.regon, xPosition, 78, {align: 'left'})
        this.printDashedLine(xPosition, 79, lineEndPosition, 9)

        xPosition = this.sizes.rightSection
        lineEndPosition = this.sizes.lineRightEnd
      }

      this.doc.text('Nazwa jednostki samorządu terytorialnego', xPosition, 42, {align: 'left'})
      this.doc.text(userModel.localGovermentUnit, xPosition, 50, {align: 'left'})
      this.printDashedLine(xPosition, 53, lineEndPosition, 9)

      this.doc.text('Kod TERYT', xPosition, 59, {align: 'left'})
      this.doc.text(userModel.terytCode, xPosition, 64, {align: 'left'})
      this.printDashedLine(xPosition, 66, lineEndPosition, 9)
    },


    // TABLE
    createPdfTable (index) {
      let tableContainer = document.getElementsByClassName('table-section__table')

      if (tableContainer == null) {
        return
      }
      this.doc.addPage()
      this.pagesAlreadyExist(index)

      let tables = []
      Array.from(tableContainer).forEach(element => {
        let table = element.getElementsByTagName('table')[0]
        tables.push(table)
      })

      let pdfPageTables = this.$store.getters['pdf/pdfContent'].content.pages[index].tables
      let pdfPageFootnote = this.$store.getters['pdf/pdfContent'].content.pages[index].footnote
      let pdfPageInputRow = this.$store.getters['pdf/pdfContent'].content.pages[index].input
      let isLastPage = this.$store.getters['pdf/pdfContent'].content.pages[index].isLastPage

      let currentYposition = 15
      for (let i = 0; i < tables.length; i++) {

        if (tables.length > 1 && i > 0) {
          currentYposition += 10
        }

        let table = tables[i]
        currentYposition = this.createTitle(currentYposition, pdfPageTables[i].text)

        if (pdfPageTables[i].notice != null) {
          currentYposition = this.createTitleNotice(currentYposition, pdfPageTables[i].notice)
        }

        let getYposition = (data) => {
          currentYposition = data.cursor.y
        }

        // Hide "Dodaj szkole" button before create PDF
        let addSchoolButtons = Array.from(table.getElementsByClassName('btn-add-school'))
        let addSchoolButtonName = ''
        addSchoolButtons.forEach(button => {
          addSchoolButtonName = button.innerHTML
          button.innerHTML = ''
        })

        this.doc.autoTable({
          startY: currentYposition,
          startX: 10,
          html: table,
          useCss: true,
          didDrawPage: getYposition,
          styles: {
            font: 'OpenSans',
            fontStyle: 'normal',
            whiteSpace: 'preWrap',
          },
        })

        // Show "Dodaj szkole" button before create PDF
        addSchoolButtons.forEach(button => {
          button.innerHTML = addSchoolButtonName
        })
      }

      if (pdfPageInputRow != null) {
        currentYposition = this.createInputRow(pdfPageInputRow, this.doc, currentYposition, index)
      }

      if (pdfPageFootnote != null && !isLastPage) {
        this.createFootnote(pdfPageFootnote, this.doc, currentYposition)
      }

      let startIndex = this.doc.internal.getNumberOfPages() - this.pagesCounter[index].startPage
      this.pagesCounter[index].startIndex = startIndex
    },

    createTitle (yPosition, title) {
      this.doc.setFontSize(11)
      let titleText = this.splitWideText(title)
      this.doc.text(titleText, this.sizes.xStart, yPosition, {align: 'left'})

      yPosition += this.getTextHeight(titleText)

      return yPosition
    },

    createTitleNotice (yPosition, text) {
      this.doc.setFontSize(9)
      this.doc.setFont('OpenSans', 'italic')

      text = this.getTextToPdf(text)
      this.doc.text(text, this.sizes.xStart, yPosition += 1, {align: 'left'})

      this.doc.setFont('OpenSans', 'normal')
      this.doc.setFontSize(10)

      return yPosition += this.getTextHeight(text) + 2
    },

    // Creates an input row, if exists, after the table
    createInputRow (pdfPageInputRow, doc, currentYposition, index) {
      doc.setFontSize(10)

      let formNumber = this.$store.getters['pdf/formNumber'] ? this.$store.getters['pdf/formNumber'] + 1 : 1

      let value = this.$store.getters['user/userModel'].afterTableInputRow[index + '-' + formNumber]
      value = value ? value : 0

      let text = `${pdfPageInputRow}:   ${value} zł`
      let inputText = this.getTextToPdf(text)
      let inputTextHeight = this.getTextHeight(inputText)

      if ((currentYposition + inputTextHeight + 10) > 190) {
        doc.addPage()
        currentYposition = this.sizes.yStart
      }

      doc.text(inputText, this.sizes.xStart, currentYposition += 10)
      currentYposition += inputTextHeight

      return currentYposition
    },

    createFootnote (footnote, doc, currentY) {
      let footnoteContentHeight = 0
      let footnoteTextsToPdf = []
      let footnoteTextsHeight = []

      doc.setFontSize(8)
      // prints footnote on last page
      if (footnote.isNested) {

        let nestedIndexes = ['a', 'b', 'c']
        let yPosition = currentY + 60

        this.printFootnoteLine(yPosition)
        yPosition += this.sizes.footnoteStartAfterLine

        let footnoteStartText = `*) ${footnote.liFirst}`
        if (footnote.liFirstClass === 'double-star') {
          footnoteStartText = `**) ${footnote.liFirst}`
        }
        doc.text(footnoteStartText, this.sizes.xStart, yPosition)
        yPosition += this.getTextHeight(footnoteStartText) + this.sizes.footnoteNewLine

        let nestedFootnotes = footnote.olNested

        for (let i = 0; i < nestedFootnotes.length; i++) {
          let text = nestedFootnotes[i].text
          doc.text(`${i + 1}) ${text}`, this.sizes.xStart + 4, yPosition)

          yPosition += this.getTextHeight(text) + this.sizes.footnoteNewLine

          for (let z = 0; z < nestedFootnotes[i].childs.length; z++) {
            text = nestedFootnotes[i].childs[z]

            doc.text(`${nestedIndexes[z]}) ${text}`, this.sizes.xStart + 8, yPosition)

            yPosition += this.getTextHeight(text) + this.sizes.footnoteNewLine
          }

        }

        return
      }

      let footnoteIndex = footnote.start != null
        ? footnote.start
        : 1

      for (let i = 0; i < footnote.olNotNested.length; i++) {
        let footnoteText = `${footnoteIndex++}) ${footnote.olNotNested[i].text}`

        footnoteTextsToPdf.push(this.getTextToPdf(footnoteText))
        footnoteTextsHeight.push(this.getTextHeight(footnoteText))

        let footnoteTextHeight = this.getTextHeight(footnoteText)

        footnoteText = this.splitWideText(footnoteText)
        footnoteContentHeight += footnoteTextHeight
      }

      let yPosition = currentY + 20
      // we need to jump to next page then
      if (yPosition > 190) {
        yPosition = this.sizes.yStart
        doc.addPage()
      }

      if ((yPosition + footnoteContentHeight) > 190) {
        yPosition = this.sizes.yStart
        doc.addPage()
      }

      this.printFootnoteLine(yPosition)
      yPosition += this.sizes.footnoteStartAfterLine

      for (let i = 0; i < footnoteTextsToPdf.length; i++) {
        doc.text(footnoteTextsToPdf[i], this.sizes.xStart, yPosition, {align: 'justify'})
        yPosition += footnoteTextsHeight[i] + this.sizes.footnoteNewLine
      }

      return doc
    },

    // LAST PAGE
    createLastPage (doc) {
      let pdfContent = this.$store.getters['pdf/pdfContent']
      let userModel = this.$store.getters['user/userModel']
      let attachmentNumber = pdfContent.attachmentNumber
      let formNumber = this.$store.getters['pdf/formNumber'] ? this.$store.getters['pdf/formNumber'] + 1 : 1

      let inputs1_5 = [
        'Załącznik nr 1',
        'Załącznik nr 2',
        'Załącznik nr 4',
        'Załącznik nr 5',
      ]

      let inputs6_7 = [
        'Załącznik nr 6',
        'Załącznik nr 7'
      ]

      let inputs8_9 = [
        'Załącznik nr 8',
        'Załącznik nr 9'
      ]

      if (pdfContent.content.pages.length == 0) {
        return
      }

      let currentY = this.sizes.yStart
      let currentX = this.sizes.xStart
      let lastPageData = [...pdfContent.content.pages].pop()

      doc.addPage()
      doc.setFontSize(10)

      let pageTitle = this.getTextToPdf(lastPageData.text)

      // If the input title has an user input in it
      if (lastPageData.isInput) {
        pageTitle = this.getTextToPdf(`${lastPageData.text}`)
        doc.text(pageTitle , currentX, currentY, {align: 'left', maxWidth: this.sizes.pdfWidth})

        let xPosition = this.getTextWidth(pageTitle[pageTitle.length - 1]) + this.sizes.xStart + 2
        currentY += this.getTextHeight(pageTitle[pageTitle.length - 1]) + 1

        if (pageTitle.length == 1) {
          xPosition = this.sizes.xStart
        }

        let userValue = `${userModel.expensesSummary[formNumber] ? userModel.expensesSummary[formNumber] : ''}`
        doc.text(userValue, xPosition, currentY, {align: 'left'})
        doc.text(' zł', xPosition + 36, currentY)
        this.printDashedLine(xPosition, currentY += 1, xPosition + 40, 10)
      }
      else {
        doc.text(pageTitle, currentX, currentY, {align: 'left'})
      }
      currentY += this.getTextHeight(pageTitle) + 8

      if (inputs1_5.includes(attachmentNumber) || (formNumber <= 5)) {
        currentY = this.createLastInputsPdf1_5(lastPageData, userModel, currentY, currentX += 5)
      }
      if (inputs6_7.includes(attachmentNumber) || (formNumber >= 6 && formNumber <= 7)) {
        this.createLastInputsPdf6_7(userModel, currentY, currentX += 5)
      }
      if (inputs8_9.includes(attachmentNumber) || (formNumber >= 8)) {
        currentY = this.createLastInputsPdf8_9(userModel, currentY, currentX += 5)
        currentY += 10
      }

      currentY = this.createLastPageSigns(doc, currentY, pdfContent)
      this.createFootnote(lastPageData.footnote, doc, currentY)
    },

    createLastPageSigns (doc, currentY, pageData) {
      let xStart = this.sizes.xStart
      let yPosition = currentY

      let signText = `pieczęć i podpis dyrektora szkoły*)`
      if (["Załącznik nr 2", "Załącznik nr 7"].includes(pageData.attachmentNumber)) {
        signText = `pieczęć i podpis dyrektora szkoły**)`
      }
      if (pageData.signText) {
        // Todo
        signText = pageData.signText
      }

      doc.setFontSize(8)
      yPosition += 60
      let dateText = `data sporządzenia`
      let dateTextWidth = this.getTextWidth(dateText)

      xStart += 20
      doc.setFontSize(10)

      let userDate = this.$store.getters['user/userModel'].pdfDate
      let formattedDate = ''
      if(userDate) {
         formattedDate = new Date(userDate).toISOString().replace(/T.*/,'').split('-').reverse().join('-')
      }
      doc.text(formattedDate, xStart, yPosition - 3, {align: 'left'})

      doc.setFontSize(8)
      this.printDashedLine((xStart - dateTextWidth / 2), yPosition, (xStart + dateTextWidth + dateTextWidth / 2))
      doc.text(dateText, xStart, yPosition + 3)
      xStart += dateTextWidth + 80

      let signTextWidth = this.getTextWidth(signText)
      xStart += (signTextWidth / 2)
      this.printDashedLine((xStart - signTextWidth / 1.2), yPosition, (xStart + signTextWidth / 1.2))
      doc.text(signText, xStart, yPosition + 3, {align: 'center'})

      return yPosition
    },

    // Creates last user inputs for pdf 1 - 4 and pdf 5
    createLastInputsPdf1_5 (lastPageData, userModel, currentY, currentX) {
      let formNumber = this.$store.getters['pdf/formNumber'] ? this.$store.getters['pdf/formNumber'] + 1 : 1

      let pageSubText = `${this.getTextToPdf(lastPageData.subtext)}:  ${userModel.expensesSummary[formNumber] ? userModel.expensesSummary[formNumber] : ''} zł, z tego:`
      this.doc.text(pageSubText, currentX, currentY, {align: 'left'})
      currentY += this.getTextHeight(pageSubText) + 8

      let eCurrent = `- wydatki bieżące:        ${userModel.expensesCurrent[formNumber] ? userModel.expensesCurrent[formNumber] : ''} zł`;
      this.doc.text(eCurrent, currentX, currentY, {align: 'left'})
      currentY += this.getTextHeight(eCurrent) + 1

      let eFinancial = `- wydatki majątkowe:  ${userModel.expensesFinancial[formNumber] ? userModel.expensesFinancial[formNumber] : ''} zł`;
      this.doc.text(eFinancial, currentX, currentY, {align: 'left'})
      currentY += this.getTextHeight(eFinancial)

      return currentY
    },

    createLastInputsPdf6_7 (userModel, currentY, currentX) {
      let formNumber = this.$store.getters['pdf/formNumber'] ? this.$store.getters['pdf/formNumber'] + 1 : 1

      let eCurrent = `Łączna liczba uczniów objętych dotacją celową `
      this.doc.text(eCurrent, currentX, currentY, {align: 'left'})
      currentX = this.getTextWidth(eCurrent) + this.sizes.xStart + 6

      eCurrent = `${userModel.studentsSummary[formNumber] ? userModel.studentsSummary[formNumber] : ''}`
      this.doc.text(eCurrent, currentX, currentY, {align: 'left'})
      this.printDashedLine(currentX, currentY += 1, currentX + 40, 10)
      currentY += this.getTextHeight(eCurrent) + 1

      return currentY
    },

    createLastInputsPdf8_9 (userModel, currentY, currentX) {
      let formNumber = this.$store.getters['pdf/formNumber'] ? this.$store.getters['pdf/formNumber'] + 1 : 1

      let text = `Łączna liczba uczniów objętych dotacją celową ${userModel.studentsSummary[formNumber] ? userModel.studentsSummary[formNumber] : ''}`
      this.doc.text(text, currentX, currentY, {align: 'left'})
      let textWidth = this.getTextWidth('Łączna liczba uczniów objętych dotacją celową ') + this.sizes.xStart + 6
      this.printDashedLine(textWidth, currentY += 1, textWidth + 40, 10)
      currentY += this.getTextHeight(text) + 2

      text = `Łączna liczba szkół objętych dotacją celową       ${userModel.schoolsSummary[formNumber] ? userModel.schoolsSummary[formNumber] : ''}`
      let textWidth_2 = this.getTextWidth('Łączna liczba szkół objętych dotacją celową ')
      if (textWidth_2 > textWidth) {
        textWidth = textWidth_2
      }
      this.doc.text(text, currentX, currentY, {align: 'left'})
      this.printDashedLine(textWidth, currentY += 1, textWidth + 40, 10)
      currentY += this.getTextHeight(text) + 1

      return currentY
    },


    // HELPERS
    getTextToPdf (text) {
      return this.splitWideText(text)
    },

    getTextHeight (text) {
      let x = this.getTextToPdf(text)
      return this.doc.getTextDimensions(x).h
    },

    getTextWidth (text) {
      let x = this.getTextToPdf(text)
      return this.doc.getTextDimensions(x).w
    },

    printDashedLine (x, y, xEnd, fontSize = 8) {
      this.doc.setFontSize(8)
      this.doc.setLineDash([0.5, 0.5], 0)
      this.doc.setDrawColor(0, 0, 0)
      this.doc.line(x, y, xEnd, y)
      this.doc.setFontSize(fontSize)
    },

    printFootnoteLine (y) {
      let x = this.sizes.xStart
      this.doc.setLineDash(0, 0)
      this.doc.setDrawColor(0, 0, 0)
      this.doc.line(x, y, this.sizes.footnoteEndX, y)
    },

    splitWideText (textToSplit, checkbox = false) {
      let maxStringLength = this.sizes.maxStringLength
      if (checkbox) {
        maxStringLength = this.sizes.maxStringCheckbox
      }

      let splitted = this.doc.splitTextToSize(textToSplit, maxStringLength)
      return splitted
    },


    // PDF SAVE METHODS
    savePdfPage () {
      let fileName = 'Formularz ' + (this.$store.getters['pdf/formNumber'] ? this.$store.getters['pdf/formNumber'] + 1 : 1) + '.pdf'

      if (!this.lastPageAdded) {
        this.createLastPage(this.doc)
        this.lastPageAdded = true
      }
      else {
        let lastPageIndex = this.doc.internal.getNumberOfPages()
        this.doc.deletePage(lastPageIndex)
        this.createLastPage(this.doc)
      }

      this.doc.save(fileName, {
        returnPromise: true
      })
      .then()
    },

    savePdfPageWithTable (index) {
      this.createPdfTable(index)

      let fileName = 'Formularz ' + (this.$store.getters['pdf/formNumber'] ? this.$store.getters['pdf/formNumber'] + 1 : 1) + '.pdf'

      if (!this.lastPageAdded) {
        this.createLastPage(this.doc)
        this.lastPageAdded = true
      }
      else {
        let lastPageIndex = this.doc.internal.getNumberOfPages()
        this.doc.deletePage(lastPageIndex)
        this.createLastPage(this.doc)
      }

      this.doc.save(fileName, {
        returnPromise: true
      })
      .then()
    },

    pagesAlreadyExist (index) {
      if (this.pagesCounter[index] != null) {
        let lastPageDoc = this.doc.internal.getNumberOfPages()
        let startTablePage = this.pagesCounter[index].startPage

        for (let i = lastPageDoc; i > startTablePage; i--) {
          this.doc.deletePage(i)
        }
      }

      else {
        this.pagesCounter.push({
          startPage: this.doc.internal.getNumberOfPages(),
          pagesAdded: 0
        })
      }

      this.lastPageAdded = false
    }
  }
}