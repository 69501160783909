<template>
  <v-footer
    color="grey lighten-3"
    padless
  >
    <v-container>
      <v-row class="footer__info-section footer__info-section--main">
        <v-col
          cols="12"
          offset-sm="4"
          offset-md="0"
          sm="12"
          md="12">
            <h2 class="black--text">Menu</h2>
            <v-btn
              color="black"
              text
              rounded
              class="my-2"
              to="/"
            >
              Strona główna
            </v-btn>
            <v-btn
              color="black"
              text
              rounded
              class="my-2"
              to="/polityka-prywatnosci"
            >
              Polityka prywatności
            </v-btn>
            <v-btn
              color="black"
              text
              rounded
              class="my-2"
              to="/dostepnosc"
            >
              Deklaracja dostępności
            </v-btn>
<!--            <v-btn-->
<!--              color="black"-->
<!--              text-->
<!--              rounded-->
<!--              class="my-2"-->
<!--              to="/pliki"-->
<!--            >-->
<!--            OBOWIĄZUJĄCE AKTY PRAWNE-->
<!--            </v-btn>-->
            <v-btn
              color="black"
              text
              rounded
              class="my-2"
              to="/reset"
            >
            Wyczyść dane
            </v-btn>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-text class="black--text">
        <strong>&copy; {{ new Date().getFullYear() }} - Informatyczne Centrum Edukacji i Nauki</strong>
      </v-card-text>
    </v-container>
  </v-footer>
</template>


<script>
export default {
  name: 'Footer',

  data: function () {
    return {
      icons: [
        'mdi-facebook',
        'mdi-facebook',
        'mdi-facebook',
      ]
    }
  },

  methods: {
    getMenuItem(index) {
      return `Menu - ${index}`
    }
  }

}
</script>
