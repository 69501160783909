<template>
  <div class="navigation navigation--switcher">
    <v-btn
      elevation="2"
      color="primary"
      @click="pdfPreviousPage()"
    >
      {{ previousBtnText }}
    </v-btn>

    <v-btn
      elevation="2"
      color="primary"
      @click="pdfNextPage()"
    >
      {{ nextBtnText}}
    </v-btn>
  </div>
</template>

<script>
import router from '../../router/index'

export default {
  name: 'PdfPageSwitcher',
  data: function () {
    return {
      currentPageIndex: Number,

      previousBtnText: 'Strona główna',
      nextBtnText: 'Następna strona'
    }
  },

  props: {
    pdfPageIndex: {
      type: Number,
      required: true
    },
    pdfPagesLength: {
      type: Number,
      required: true
    }
  },

  created: function () {
    this.currentPageIndex = this.pdfPageIndex
  },

  watch: {
    currentPageIndex: function () {
      let pageIndex = this.currentPageIndex
      let lastPage = this.pdfPagesLength - 1

      if (pageIndex <= -1) {
        this.previousBtnText = 'Strona główna'
      }
      else {
        this.previousBtnText = 'Poprzednia strona'
      }

      if (pageIndex == lastPage) {
        this.nextBtnText = 'Generuj plik PDF'
      }
      else {
        this.nextBtnText = 'Następna strona'
      }
    }
  },

  methods: {
    pdfNextPage () {
      if (this.currentPageIndex >= this.pdfPagesLength - 1) {
        this.$forceUpdate();
        this.$emit('generatePdf')
        return
      }
      this.$emit('pageChanged', ++this.currentPageIndex)
      this.$store.dispatch('pdf/setPdfPageIndex', this.currentPageIndex)
      this.$forceUpdate();
    },

    pdfPreviousPage () {
      if (this.currentPageIndex <= -1) {
        router.push('/')
        return
      }
      this.$emit('pageChanged', --this.currentPageIndex)
      this.$store.dispatch('pdf/setPdfPageIndex', this.currentPageIndex)
      this.$forceUpdate();
    }
  }
}
</script>