<template>
  <div class="first-page">

    <pdf-header
      :attachmentName="attachmentName"
      :attachmentNumber="attachmentNumber"
      :pageTitle="pageTitle"
      :mainTitle="mainTitle"

      :instituteName="instituteName"
      :notice="notice"

      :localGovermentUnit="localGovermentUnit"
      :localGovermentUnitOnly="localGovermentUnitOnly"
    />

    <div class="first-page__content">
      <p class="paragraph__main-title" v-html="mainTitle"></p>

      <div
        v-if="ifCheckboxes"
        class="first-page__checkboxes first-page__checkboxes--pdf-students">
        <p class="first-page__checkboxes__title">Dotyczy uczniów:</p>
        <label v-for="(checkbox, index) in checkboxes" :key="index"
          class="first-page__checkboxes__checkbox">
            <input
              type="checkbox"
              :name="`check-${index+1}`"
              :checked="getStudentCheckbox(index)"
              @change="changeCheckboxes"
            >
            {{ checkbox }}
        </label>

        <p class="first-page__checkboxes__notice text-center">
          <br><strong>(należy zaznaczyć właściwy kwadrat)</strong>
        </p>
      </div>

      <p class="paragraph__notice"> {{ notice }}</p>
    </div>

  </div>
</template>

<script>
import PdfHeader from '../../Shared/PdfHeader'

export default {
  name: 'PageOne',

  props: {
    attachmentNumber: String,
    attachmentName: String,
    pageTitle: String,
    mainTitle: String,
    instituteName: String,
    notice: String,
    localGovermentUnit: String,
    localGovermentUnitOnly: Boolean,
    checkboxes: Array
  },

  components: {
    'pdf-header': PdfHeader
  },

  computed: {
    renderGovermentOnly () {
      return !this.localGovermentUnitOnly
    },

    ifCheckboxes () {
      return this.checkboxes.length > 0
        ? true
        : false
    },

    getModel () {
      return this.$store.getters['user/userModel']
    }
  },

  methods: {
    getModelValue (property) {
      return this.getModel[property]
    },

    changeCheckboxes () {
      let model = this.$store.getters['user/userModel']
      let checkboxes = document.querySelectorAll('.first-page__checkboxes--pdf-students input')

      let index = 0
      let currentCheckbox = event.target
      checkboxes.forEach(checkbox => {
        if(checkbox != currentCheckbox) {
          checkbox.checked = false
        }
        let checkName = checkbox.name
        model.studentCheckboxes[index++][checkName] = checkbox.checked
      })

      this.$store.commit('user/updateModel', model)
    },

    getStudentCheckbox (index) {
      return this.getModelValue('studentCheckboxes')[index][`check-${index+1}`]
    }
  }
}
</script>