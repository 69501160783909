<template>
  <v-container>
    <v-alert
      dense
      type="info"
    >
      Wypełnij poniższy formularz. Na ostatniej stronie będzie możliwość wygenerowania PDF wraz z wprowadzonymi danymi.
      <span 
        v-if="formNumber >= 8"
      ><br>
        Wpisywane nazwy szkoły w poz. 1 są przenoszone automatycznie do pozostałych pozycji w każdej części formularza.
      </span>
    </v-alert>

    <v-progress-linear
      v-model="progress"
      height="25"
      color="light-blue text--white"
      striped
    >
      <strong style="color: #fff;">Postęp: {{progress}}%</strong>
    </v-progress-linear>
  </v-container>  
</template>

<script>
export default {
  name: 'PdfIntro',

  props: {
    pdfPageIndex: {
      type: Number,
      required: true
    },
    pdfPagesLength: {
      type: Number,
      required: true
    },
    form: {
      type: String,
      default: ""
    },
    formNumber: Number
  },

  computed: {
    progress: function() {
      return ((this.pdfPageIndex + 1) / this.pdfPagesLength) * 100;
    }
  }
}
</script>