import 'jspdf-autotable'
// import jsPDF from 'jspdf'

const state = () => ({
  doc: undefined,

  pagesCounter: []
})

const mutations = {
  updateDoc (state, doc) {
    state.doc = doc
  },

  updatePagesCounter (state, pagesCounter) {
    state.pagesCounter = pagesCounter
  }
}

const actions = {
  updateDoc ({commit}, doc) {
    commit('updateDoc', doc)
  },

  updatePagesCounter ({commit}, pagesCounter) {
    commit('updatePagesCounter', pagesCounter)
  }
}

const getters = {
  doc: state => state.doc,
  pagesCounter: state => state.pagesCounter
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}