<template>
  <v-row class="api">
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Pobierz dane szkoły z wykorzystaniem RSPO
        </v-btn>
      </template>

      <v-card
        :loading="loading"
      >
        <v-card-title class="headline">
          Wprowadź kod RSPO placówki
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Kod RSPO*"
                  required
                  type="number"
                  v-model="rspoId"
                  :input="getUserRspo()"
                ></v-text-field>
              </v-col>
            </v-row>
            <small>*To pole jest wymagane</small>
          </v-container>
        </v-card-text>

        <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
        ></v-progress-linear>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="getApiData()"
            :loading="loading"
            :disabled="apiCallBtnDisabled"
          >
            Pobierz dane
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="apiResponseDialog"
      max-width="300px"
      @click:outside="closeDialogs"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text v-if="apiResponseIsSuccess">
          Dane placówki zostały pobrane poprawnie.
        </v-card-text>

        <v-card-text v-else>
          Podany numer RSPO placówki nie istnieje: <span style="font-weight: bold">{{ rspoId }}</span>.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="apiResponseIsSuccess"
            color="blue darken-1"
            text
            @click="closeDialogs"
          >
            Ok
          </v-btn>
          <v-btn
            v-else
            color="blue darken-1"
            text
            @click="apiResponseDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios'

export default {
  name: 'RspoApi',

  data: function () {
    return {
      dialog: false,
      loading: false,
      apiResponseDialog: false,
      apiResponseIsSuccess: false,
      apiCallBtnDisabled: true,
      rspoId: '',
      apiRespone: {
        type: Object
      }
    }
  },

  props: [

  ],

  methods: {
    getUserRspo () {
      if (this.rspoId >= 1 || this.rspoId != '') {
        this.apiCallBtnDisabled = false

        return
      }
      this.apiCallBtnDisabled = true
    },

    getApiData () {
      this.loading = true
      let requestUrl = 'https://dotacjepodreczniki-panel.mein.gov.pl/form/rspo/' + this.rspoId //'https://api-rspo.mein.gov.pl/api/placowki/' + this.rspoId

      
      axios.get(requestUrl)
        .then((response) => {
          if(response) {
            this.loading = false
            this.apiResponseDialog = true
            this.apiResponseIsSuccess = true

            this.setApiDataUserModel(response)
          } else {
            this.loading = false
            this.apiResponseDialog = true
            this.apiResponseIsSuccess = false 
          }
        })
        .catch((error) => {
          console.log(error)
          // if (error.response.status === 404) {
            this.loading = false
            this.apiResponseDialog = true
            this.apiResponseIsSuccess = false
          // }
        })
    },

    // If api request has status 200
    closeDialogs () {
      this.apiResponseDialog = false
      this.loading = false
      this.dialog = false
    },

    setApiDataUserModel (response) {
      let userModel = this.$store.getters['user/userModel']
      response = response.data

      console.log(response)

      userModel['address'] = this.getAddress(response)
      userModel['instituteName'] = response.nazwa
      userModel['regon'] = response.regon
      userModel['localGovermentUnit'] = response.podmiotPrzekazujacyDaneDoRSPO.nazwa
      // userModel['terytCode'] = response.wojewodztwoKodTERYT

      this.$store.dispatch('user/updateModel', userModel)
    },

    getAddress (response) {
      return `${response.kodPocztowy} ${response.miejscowosc}, ${response.ulica} ${response.numerBudynku} ${response.numerLokalu != '' ? '/ ' + response.numerLokalu : ''}`
    }
  }

}
</script>


<style lang="scss" scoped>

.v-card {
  &__text {
    text-align: left;
  }
}
</style>