const defaultModel = {
  instituteName: '',
  address: '',
  regon: '',
  localGovermentUnit: '',
  terytCode: '',
  priceSummary: '',
  studentsSummary: [],
  afterTableInputRow: [],

  // first page pdf-3
  value: '',
  valueVerbally: '',
  bankInfo: '',

  // For first pdf page
  firstEdit: false,
  againFirstEdit: false,
  updateEdit: false,
  studentCheckboxes: [
    { 'check-1': false},
    { 'check-2': false},
    { 'check-3': false},
    { 'check-4': false},
    { 'check-5': false},
    { 'check-6': false},
    { 'check-7': false},
    { 'check-8': false},
    { 'check-9': false},
    { 'check-10': false},
  ],

  // For last page summary
  expensesSummary: [],
  expensesCurrent: [],
  expensesFinancial: [],
  schoolsSummary: [],

  pdfDate: ''
}

const state = () => ({
  userModel: {
    instituteName: '',
    address: '',
    regon: '',
    localGovermentUnit: '',
    terytCode: '',
    priceSummary: '',
    studentsSummary: [],
    afterTableInputRow: [],

    // first page pdf-3
    value: '',
    valueVerbally: '',
    bankInfo: '',


    // For first pdf page
    firstEdit: false,
    againFirstEdit: false,
    updateEdit: false,
    studentCheckboxes: [
      { 'check-1': false },
      { 'check-2': false },
      { 'check-3': false },
      { 'check-4': false },
      { 'check-5': false },
      { 'check-6': false },
      { 'check-7': false },
      { 'check-8': false },
      { 'check-9': false }
    ],

    // For last pdf page summary
    expensesSummary: [],
    expensesCurrent: [],
    expensesFinancial: [],
    schoolsSummary: [],

    pdfDate: ''
  },
})

const mutations = {
  updateModel (state, userData) {
    state.userModel = userData
  },

  resetModel (state) {
    state.userModel = defaultModel
  },
}

const actions = {
  updateModel ({commit}, model) {
    commit('updateModel', model)
  }
}

const getters = {
  userModel: state => state.userModel
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}