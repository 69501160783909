<template>
  <div class="table-section">

    <p class="table-section__title">
      {{ tableContent.text }}
    </p>

    <div class="table-section__table">
      <table :id="'table-' + page + '-' + formNumber">

        <thead>
          <tr>
            <td></td>
            <td></td>
            <th colspan="8" class="school-checkbox">
              <div>
                <input
                  type="checkbox"
                  name="school-primary"
                  @change="changeRadio"
                  id="check1"
                  :checked="this.$store.getters['tableSchoolType'][this.formNumber] == 'school-primary'"
                >
                <span v-if="this.$store.getters['tableSchoolType'][this.formNumber] == 'school-primary'" class="hidden-checkbox-pdf" style="display: none;">[X]</span>
                <span v-if="this.$store.getters['tableSchoolType'][this.formNumber] != 'school-primary'" class="hidden-checkbox-pdf" style="display: none;">[ ]</span>
                &nbsp;
                <label for="check1">
                  {{tableContent.headerCheckbox1 ? tableContent.headerCheckbox1 : 'Szkoła podstawowa'}}
                </label><br>
              </div>
              <div>
                <input 
                  type="checkbox" 
                  name="school-art" 
                  @change="changeRadio" 
                  id="check2"
                  :checked="this.$store.getters['tableSchoolType'][this.formNumber] == 'school-art'"
                >
                <span v-if="this.$store.getters['tableSchoolType'][this.formNumber] == 'school-art'" class="hidden-checkbox-pdf" style="display: none;">[X]</span>
                <span v-if="this.$store.getters['tableSchoolType'][this.formNumber] != 'school-art'" class="hidden-checkbox-pdf" style="display: none;">[ ]</span>
                &nbsp;
                <label for="check2">
                  {{tableContent.headerCheckbox2 ? tableContent.headerCheckbox2 : 'Szkoła artystyczna realizująca kształcenie ogólne w zakresie szkoły podstawowej²⁾'}}
                </label>
              </div>
            </th>
            <td></td>
          </tr>
          <tr class="header">
            <th>Poz.</th>
            <th>Wyszczególnienie<sup>¹)</sup></th>
            <th width="80">klasa I</th>
            <th width="80">klasa II</th>
            <th width="80">klasa III</th>
            <th width="80">klasa IV</th>
            <th width="80">klasa V</th>
            <th width="80">klasa VI</th>
            <th width="80">klasa VII</th>
            <th width="80">klasa VIII</th>
            <th width="80">Razem</th>
          </tr>
          <tr class="cols-header">
            <td v-for="index in 11" :key="index">{{ index }}</td>
          </tr>
        </thead>

        <tbody>
          

          <tr v-for="(row, index) in tableContent.rows" :key="index">
            <td>{{ index + 1}}</td>
            <td style="text-align: left">
              <span v-html="row.text.replace(/\n/g, '<br />')"></span>
              <span v-for="(item, index) in row.dashed" :key="index" v-html="'<br>-  ' + item " ></span>
            </td>

            <td
              v-for="(column, j) in row.columns" :key="j"
              class="table-section__input--small"
              :class="{'table-section__input--disabled': !column}"
              @click="clickCol($event)"
              >
              <div
                v-if="column"
                class="table-section__input"
              >
                <span>{{ $store.getters.tableData[page + '-' + formNumber + '-' + index + '-' + j] }}</span>
                
                <input
                  type="text"
                  :id="'input-' + page + '-' + formNumber + '-' + index + '-' + j"
                  :class="j == 8 ? 'amount' : ''"
                  :disabled="j == 8"
                  :data-index="page + '-' + formNumber + '-' + index + '-' + j"
                  :data-row="index"
                  :data-col="j"
                  @input="updateModel(page + '-' + formNumber + '-' + index + '-' + j, $event)"
                  :value="$store.getters.tableData[page + '-' + formNumber + '-' + index + '-' + j]"
                >
              </div>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
export default {
  name: 'PageTable',

  props: {
    tableContent: Object,
    page: Number,
    formNumber: Number
  },

  data: function () {
    return {
      school: '',
      inputs: []
    }
  },

  methods: {
    refresh () {
      this.$forceUpdate()
    },
      filterNulls(items) {
          console.log(items);
          return items.filter(item => item.dashed === 'null')
      },
    clickCol (event) {
      if(event.target) {
        let inputs = event.target.getElementsByTagName('input')
        if(inputs.length) {
          inputs[0].focus()
        }
      }
    },

    changeRadio (event) {
      let currentHiddenCheckbox = event.target.parentNode.querySelector('.hidden-checkbox-pdf')

      var checkboxes = document.querySelectorAll(".school-checkbox input")
      checkboxes.forEach(checkbox => {
        if(checkbox != event.target) {
          checkbox.checked = false
          checkbox.parentNode.querySelector('.hidden-checkbox-pdf').innerHTML = '[ ]'
        }
      })

      let schoolType = {
        id: this.formNumber,
        value: event.target.name
      }
      this.$store.commit('updateTableSchoolType', schoolType)

      if(event.target.checked) {
        currentHiddenCheckbox.innerHTML = '[X]'
      } else {
        currentHiddenCheckbox.innerHTML = '[ ]'
      }
    },

    updateModel (property, event) {
      let input = event.target
      input.value = input.value.replace('.', ',')
      let model = this.$store.getters.tableData
      model[property] = input.value

      // input.parentNode.querySelector('label').innerHTML = input.value
      // console.log(input.value)

      this.$store.commit('updateTableData', model)

      this.rowSum(model, input)
      this.form1Calculation(input)
      this.form2Calculation(input)
      this.form4Calculation(input)
      this.form5Calculation(input)

      //last row and col - sum of fields
      let allAmounts = document.querySelectorAll('input.amount')
      let summaryData = {
        id: this.formNumber,
        page: this.page,
        value: allAmounts[allAmounts.length - 1].value
      }
      this.$store.commit('updateTableSum', summaryData)

      let userModel = this.$store.getters['user/userModel']
      userModel.afterTableInputRow[this.page + '-' + this.formNumber] = summaryData.value
      this.$store.dispatch('user/updateModel', userModel)


      //temporary
      let summaryField = document.querySelector('.table-summary-' + this.page + '-' + this.formNumber)
      if(summaryField) {
        summaryField = document.querySelector('.table-summary-' + this.page + '-' + this.formNumber).value = summaryData.value
      }

      this.$forceUpdate()
    },

    //calculate summary in rows
    rowSum (model, input) {
      if(!input.classList.contains('amount')) {
        let amountInput = input.closest('tr').querySelector('input.amount')
        let inputsInRow = input.closest('tr').querySelectorAll('input:not(.amount)')
        let tmp = 0;

        if(amountInput) {
          inputsInRow.forEach(valInput => {
            if(valInput.value && !isNaN(parseFloat(valInput.value))) {
              tmp += parseFloat(valInput.value.replace(',', '.'))
            }
          })

          amountInput.value = tmp.toFixed(2).replace('.', ',')
          // amountInput.parentNode.querySelector('label').innerHTML = amountInput.value

          //update store with row sum
          model[amountInput.dataset.index] = amountInput.value
          this.$store.commit('updateTableData', model)
        }
      }
    },

    //calculate summary in columns
    colSum (input, start, end) {
        //sumary only selected rows
        if(input.dataset.row >= start) {
          let amount = 0
          let value = 0
          for(var i = start; i < end; i++) {
            value = this.$store.getters.tableData[this.page + '-' + this.formNumber + '-' + i + '-' + input.dataset.col]
            if(value && !isNaN(parseFloat(value))) {
              amount += parseFloat(value.replace(',', '.'))
            }
          }

          amount = amount.toFixed(2).replace('.', ',')

          let model = this.$store.getters.tableData
          model[this.page + '-' + this.formNumber + '-' + end + '-' + input.dataset.col] = amount

          this.$store.commit('updateTableData', model)
          
          let amountInput = document.querySelector('input[data-index="' + this.page + '-' + this.formNumber + '-' + end + '-' + input.dataset.col + '"')
          if(amountInput) {
            amountInput.value = amount

            //recalculate
            this.rowSum(model, amountInput)
          }
        }
    },

    //calculation of fields
    fieldsSum(fields, result) {
      let model = this.$store.getters.tableData
      let sum = 0
      let modelValue = 0;
      
      fields.forEach(field => {
        if(field.length == 2) {
          modelValue = model[this.page + '-' + this.formNumber + '-' + field[0] + '-' + field[1]]
          if(modelValue) {
            let tmp = parseFloat(modelValue.replace(',', '.'))
            sum += tmp
          }
        }
      })

      sum = sum.toFixed(2).replace('.', ',')

      if(result && result.length == 2) {
        let resultIndex = this.page + '-' + this.formNumber + '-' + result[0] + '-' + result[1]
        model[resultIndex] = sum
        document.querySelector('input[data-index="' + resultIndex + '"]').value = sum
        this.$store.commit('updateTableData', model)
      }

      return sum
    },

    //percentage calculation
    percentage(percent, fromRow, fromCol, resultRow, resultCol) {
      var fromValue = parseFloat(this.$store.getters.tableData[this.page + '-' + this.formNumber + '-' + fromRow + '-' + fromCol])
      if(fromValue) {
        let newValue = parseFloat(fromValue * (percent / 100))

        let model = this.$store.getters.tableData
        model[this.page + '-' + this.formNumber + '-' + resultRow + '-' + resultCol] = newValue.toFixed(2).replace('.', ',')

        this.$store.commit('updateTableData', model)
      }
    },

    form1Calculation (input) {
      if((this.formNumber == 1) && (this.page == 0)) {
        // console.log('form1Calculation 1')
        this.colSum(input, 4, 12)
      } else if((this.formNumber == 1) && (this.page == 2)) {
        // console.log('form1Calculation 3')
        this.colSum(input, 3, 7)
      }
    },

    form2Calculation (input) {
      if((this.formNumber == 2) && (this.page == 0)) {
        // console.log('form1Calculation 1')
        this.colSum(input, 5, 14)
      } else if((this.formNumber == 2) && (this.page == 2)) {
        // console.log('form1Calculation 3')
        this.colSum(input, 5, 11)
      }
    },

    form4Calculation (input) {
      
      if((this.formNumber == 4) && (this.page == 0)) {
        // console.log('form4Calculation 0')
        this.colSum(input, 4, 12)
        this.percentage(1, 12, 8, 13, 8)
        this.fieldsSum([[12, 8], [13, 8]], [14, 8])
      } else if((this.formNumber == 4) && (this.page == 1)) {
        // console.log('form4Calculation 1')
        this.percentage(1, 1, 8, 2, 8)
        this.fieldsSum([[1, 8], [2, 8]], [3, 8])

      } else if((this.formNumber == 4) && (this.page == 2)) {
        this.colSum(input, 3, 7)
        this.percentage(1, 7, 8, 8, 8)
        this.fieldsSum([[7, 8], [8, 8]], [9, 8])
      }
    },

    form5Calculation (input) {
      if((this.formNumber == 5) && (this.page == 0)) {
        // console.log('form4Calculation 0')
        this.colSum(input, 5, 14)
        this.percentage(1, 14, 8, 15, 8)
        this.fieldsSum([[14, 8], [15, 8]], [16, 8])
      } else if((this.formNumber == 5) && (this.page == 1)) {
        // console.log('form4Calculation 1')
        this.percentage(1, 1, 8, 2, 8)
        this.fieldsSum([[1, 8], [2, 8]], [3, 8])

      } else if((this.formNumber == 5) && (this.page == 2)) {
        this.colSum(input, 5, 11)
        this.percentage(1, 11, 8, 12, 8)
        this.fieldsSum([[11, 8], [12, 8]], [13, 8])
      }
    },


  },

}
</script>