<template>
  <div class="table-section">
    <p class="table-section__title">
      {{ tableContent.text }}
    </p>

    <div class="table-section__table">
      <table>

        <colgroup>
          <col span="1" width="4%">
          <col span="1" width="81%">
          <col span="1" width="15%">
        </colgroup>

        <thead>
          <tr class="header">
            <th>Poz.</th>
            <th>Wyszczególnienie</th>
            <th>Kwota w zł</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td v-for="index in 3" :key="index">
              {{ index }}
            </td>
          </tr>

          <tr v-for="(row, index) in tableContent.rows" :key="index">
            <td>{{ index + 1}}</td>

            <td style="text-align: left" v-html="row.text.replace(/\n/g, '<br />')"></td>
            <td>
              <div class="table-section__input">
                <span>{{ $store.getters.tableData[page + '-' + formNumber + '-' + index + '-' + tableNumber] }}</span>
                <input 
                  type="text"
                  :class="index == tableContent.rows.length - 1 ? 'amount' : ''"
                  :id="'input-' + page + '-' + formNumber + '-' + index + '-' + tableNumber"   
                  :data-index="page + '-' + formNumber + '-' + index + '-' + tableNumber"
                  :data-row="index"
                  :data-col="tableNumber"
                  @input="updateModel(page + '-' + formNumber + '-' + index + '-' + tableNumber, $event)"
                  :value="formatValue($store.getters.tableData[page + '-' + formNumber + '-' + index + '-' + tableNumber])"
                  placeholder="0,00"
                >
              </div>
              </td>
          </tr>

        </tbody>

      </table>
    </div>

  </div>
</template>


<script>
import tableMixin from '../../../mixins/tableMixin'

export default {
  name: 'PageTable',

  props: {
    tableContent: Object,
    page: Number,
    formNumber: Number,
    tableNumber: Number
  },

  mixins: [
    tableMixin
  ],

  methods: {
    refresh () {
      this.$forceUpdate()
    },

    clickCol (event) {
      if(event.target) {
        let inputs = event.target.getElementsByTagName('input')
        if(inputs.length) {
          inputs[0].focus()
        }
      }
    },

    updateModel (property, event) {
      let input = event.target
      input.value = input.value.replace(/ /g,'').replace('.', ',')
      let model = this.$store.getters.tableData
      model[property] = input.value

      // input.parentNode.querySelector('label').innerHTML = input.value
      // console.log(input.value)

      this.$store.commit('updateTableData', model)

      this.form6Calculations()
      this.form7Calculations()

      //last row and col - sum of fields
      let allAmounts = document.querySelectorAll('input.amount')
      let allAmountsValues = 0
      allAmounts.forEach(am => {
        allAmountsValues += parseFloat(am.value.replace(',', '.'))
      })
      
      let summaryData = {
        id: this.formNumber,
        page: this.page,
        value: allAmountsValues
      }
      this.$store.commit('updateTableSum', summaryData)
      this.$emit('updated')

      // let userModel = this.$store.getters['user/userModel']
      // userModel.afterTableInputRow[this.page + '-' + this.formNumber] = summaryData.value
      // this.$store.dispatch('user/updateModel', userModel)


      this.$forceUpdate()
    },

    //calc sum of fields
    fieldsSum(fields, result) {
      let model = this.$store.getters.tableData
      let sum = 0
      let modelValue = 0;
      
      fields.forEach(field => {
        if(field.length == 2) {
          modelValue = model[this.page + '-' + this.formNumber + '-' + field[0] + '-' + field[1]]
          if(modelValue && !isNaN(parseFloat(modelValue))) {
            let tmp = parseFloat(modelValue.replace(',', '.'))
            sum += tmp
          }
        }
      })

      sum = sum.toFixed(2).replace('.', ',')

      if(result && result.length == 2) {
        let resultIndex = this.page + '-' + this.formNumber + '-' + result[0] + '-' + result[1]
        model[resultIndex] = sum
        document.querySelector('input[data-index="' + resultIndex + '"]').value = sum
        this.$store.commit('updateTableData', model)
      }

      return sum
    },

    //calc sum of fields
    fieldsDiff(fields, result) {
      let model = this.$store.getters.tableData
      let diff = 0
      let modelValue = 0;
      
      fields.forEach((field, index) => {
        if(field.length == 2) {
          modelValue = model[this.page + '-' + this.formNumber + '-' + field[0] + '-' + field[1]]
          if(modelValue && !isNaN(parseFloat(modelValue))) {
            if(index == 0) {
              diff = parseFloat(modelValue.replace(',', '.'))
            } else {
              let tmp = parseFloat(modelValue.replace(',', '.'))
              diff -= tmp
            }
          }
        }
      })

      diff = diff.toFixed(2).replace('.', ',')

      if(result && result.length == 2) {
        let resultIndex = this.page + '-' + this.formNumber + '-' + result[0] + '-' + result[1]
        model[resultIndex] = diff
        console.log(resultIndex)
        document.querySelector('input[data-index="' + resultIndex + '"]').value = diff
        this.$store.commit('updateTableData', model)
      }

      return diff
    },

    form6Calculations() {
      if((this.formNumber == 6) && (this.page == 0)) {
        this.fieldsDiff([[0, 0],[1, 0]], [2, 0])
        this.fieldsDiff([[1, 0],[3, 0]], [4, 0])
        this.fieldsSum([[2, 0],[4, 0]], [5, 0])

        this.fieldsDiff([[0, 1],[1, 1]], [2, 1])
        this.fieldsDiff([[1, 1],[3, 1]], [4, 1])
        this.fieldsSum([[2, 1],[4, 1]], [5, 1])
      } else if((this.formNumber == 6) && (this.page == 1)) {
        this.fieldsDiff([[0, 0],[1, 0]], [2, 0])
        // this.fieldsDiff([[1, 0],[3, 0]], [4, 0])
        this.fieldsDiff([[3, 0],[4, 0]], [5, 0])
        this.fieldsSum([[2, 0],[5, 0]], [6, 0])
      }
    },
    
    form7Calculations() {
      if((this.formNumber == 7) && (this.page == 0)) {
        this.fieldsDiff([[0, 0],[1, 0]], [2, 0])
        this.fieldsDiff([[1, 0],[3, 0]], [4, 0])
        this.fieldsSum([[2, 0],[4, 0]], [5, 0])

        this.fieldsDiff([[0, 1],[1, 1]], [2, 1])
        this.fieldsDiff([[1, 1],[3, 1]], [4, 1])
        this.fieldsSum([[2, 1],[4, 1]], [5, 1])
      } else if((this.formNumber == 7) && (this.page == 1)) {
        this.fieldsDiff([[0, 0],[1, 0]], [2, 0])
        // this.fieldsDiff([[1, 0],[3, 0]], [4, 0])
        this.fieldsDiff([[3, 0],[4, 0]], [5, 0])
        this.fieldsSum([[2, 0],[5, 0]], [6, 0])
      }
    }
  },
}
</script>