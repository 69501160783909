<template>
  <div>
    <page-table
      v-for="(content, index) in tables" :key="index"
      :tableContent="content"
      v-bind:page="page"
      :formNumber="formNumber"
    />

    <div
      v-if="content.input"
      class="input-section input-section--default">
      <div class="input-section__text input-section__text--additional">
        <p>
          {{ content.input }}
          <input
            type="text"
            :class="'table-summary-' + this.page + '-' + this.formNumber"
            :value="getModel['afterTableInputRow'][this.page + '-' + this.formNumber]"
            @input="updateModel($event.target.value)"
          > zł
        </p>
        <!-- v-model="getModel['afterTableInputRow'][this.page]" -->
      </div>
    </div>

  </div>

</template>

<script>
import PageTable from './Table'

export default {
  name: 'PageShared',

  data: function () {
    return {
      tables: [],
      footnoteContent: {}
    }
  },

  created: function () {
    this.updatePageData()
  },

  props: {
    content: Object,
    page: Number,
    formNumber: Number
  },

  components: {
    'page-table': PageTable,
  },

  watch: {
    content: function () {
      this.updatePageData()
    }
  },

  computed: {
    hasPageFootnote () {
      return this.content.footnote != null
        ? true
        : false
    },

    getModel () {
      return this.$store.getters['user/userModel']
    }
  },

  methods: {
    updatePageData () {
      this.tables = this.content.tables
      if (this.hasPageFootnote) {
        this.footnoteContent = this.content.footnote
      }
    },

    updateModel (value) {
      let model = this.getModel
      model['afterTableInputRow'][this.page + '-' + this.formNumber] = value

      this.$store.commit('user/updateModel', model)
    }
  }
}
</script>