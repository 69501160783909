import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Reset from '../views/Reset.vue'
import Privacy from '../views/Privacy.vue'
import Availability from '../views/Availability.vue'
import Files from '../views/Files.vue'
import Pdf1 from '../components/PdfDocuments/Pdf-1'
import Pdf2 from '../components/PdfDocuments/Pdf-2'
import Pdf3 from '../components/PdfDocuments/Pdf-3'
import Pdf4 from '../components/PdfDocuments/Pdf-4'
import Pdf5 from '../components/PdfDocuments/Pdf-5'
import Pdf6 from '../components/PdfDocuments/Pdf-6'
import Pdf7 from '../components/PdfDocuments/Pdf-7'
import Pdf8 from '../components/PdfDocuments/Pdf-8'
import Pdf9 from '../components/PdfDocuments/Pdf-9'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Dotacja celowa na podręczniki, materiały edukacyjne i materiały ćwiczeniowe'
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
    meta: {
      title: 'Dotacja celowa na podręczniki, materiały edukacyjne i materiały ćwiczeniowe'
    }
  },
  {
    path: '/polityka-prywatnosci',
    name: 'polityka-prywatnosci',
    component: Privacy,
    meta: {
      title: 'Dotacja celowa na podręczniki, materiały edukacyjne i materiały ćwiczeniowe - polityka prywatności'
    }
  },
  {
    path: '/dostepnosc',
    name: 'dostepnosc',
    component: Availability,
    meta: {
      title: 'Dotacja celowa na podręczniki, materiały edukacyjne i materiały ćwiczeniowe - deklaracja dostępności'
    }
  },
  {
    path: '/pliki',
    name: 'pliki',
    component: Files,
    meta: {
      title: 'Obowiązujące akty prawne'
    }
  },
  {
    path: '/zalacznik-1',
    name: 'zalacznik-1',
    component: Pdf1,
    props: true
  },
  {
    path: '/zalacznik-2',
    name: 'zalacznik-2',
    component: Pdf2,
    props: true
  },
  {
    path: '/zalacznik-3',
    name: 'zalacznik-3',
    component: Pdf3,
    props: true
  },
  {
    path: '/zalacznik-4',
    name: 'zalacznik-4',
    component: Pdf4,
    props: true
  },
  {
    path: '/zalacznik-5',
    name: 'zalacznik-5',
    component: Pdf5,
    props: true
  },
  {
    path: '/zalacznik-6',
    name: 'zalacznik-6',
    component: Pdf6,
    props: true
  },
  {
    path: '/zalacznik-7',
    name: 'zalacznik-7',
    component: Pdf7,
    props: true
  },
  {
    path: '/zalacznik-8',
    name: 'zalacznik-8',
    component: Pdf8,
    props: true
  },
  {
    path: '/zalacznik-9',
    name: 'zalacznik-9',
    component: Pdf9,
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
