<template>

  <v-container class="first-page">
    <v-row class="first-page__info">
      <v-col
        cols="12"
        class="first-page__header">
        <p class="paragraph__pdf-one-start">{{ attachmentName }}</p>
        <p class="paragraph__attachment">{{ attachmentNumber }}</p>
        <p class="paragraph__heading"> {{ pageTitle }}</p>
      </v-col>
    </v-row>

    <v-row class="first-page__entry-first">

      <div
        class="first-page__adress"
        v-if="renderGovermentOnly"
      >
        <p class="paragraph__one">{{ instituteName }}</p>
        <input type="text"
          :value="getInputValue('instituteName')"
          @input="updateModel('instituteName', $event.target.value)"
        >
        <br>
        <br>
        <p class="paragraph__two">Adres</p>
        <input type="text"
          :value="getInputValue('address')"
          @input="updateModel('address', $event.target.value)"
        >
        <br>
        <p class="paragraph__regon paragraph--capitalize">Regon</p>
        <input type="text"
          :value="getInputValue('regon')"
          @input="updateModel('regon', $event.target.value)"
        >
      </div>

      <div
        class="first-page__goverment"
      >
        <p class="paragraph__one"> {{ localGovermentUnit }}</p>
        <input type="text"
          :value="getInputValue('localGovermentUnit')"
          @input="updateModel('localGovermentUnit', $event.target.value)"
        >
        <br>
        <p class="paragraph__two">Kod TERYT</p>
        <input type="text"
          :value="getInputValue('terytCode')"
          @input="updateModel('terytCode', $event.target.value)"
        >
      </div>
    </v-row>
    <template v-if="![3, 4, 7, 8].includes(currentForm)">
      <rspo-api />
    </template>

  </v-container>

</template>

<script>
import RspoApi from '../Shared/RspoApi'

export default {
  name: 'PdfHeader',

  props: {
    attachmentName: String,
    attachmentNumber: String,
    pageTitle: String,
    mainTitle: String,
    instituteName: String,
    notice: String,
    localGovermentUnit: String,
    localGovermentUnitOnly: Boolean
  },

  components: {
    'rspo-api': RspoApi
  },

  computed: {
    renderGovermentOnly () {
      return !this.localGovermentUnitOnly
    },

    getModel () {
      return this.$store.getters['user/userModel']
    },

    currentForm () {
      return this.$store.getters['pdf/formNumber']
    }
  },

  methods: {
    getInputValue (property) {
      return this.getModel[property]
    },

    updateModel (property, newValue) {
      let model = this.$store.getters['user/userModel']
      model[property] = newValue

      this.$store.commit('user/updateModel', model)
    }
  }

}
</script>