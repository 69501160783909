<template>
  <div class="table-section">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      width="500"
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          Potwierdzenie usunięcia
        </v-toolbar>

        <v-card-text>
          <div class="pa-12">
            Czy na pewno chcesz usunąć wybraną szkołę?<br>
            "{{ deleteName }}"
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Nie
          </v-btn>
          <v-btn
            color="red"
            text
            @click="deleteSchool(deleteIndex)"
          >
            Tak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <p class="table-section__title">
      {{ tableContent.text }}
    </p>
    <p
      v-if="textHasNotice"
      class="table-section__notice">
      {{ tableContent.notice }}
    </p>

    <div class="table-section__table">
      <table>
        <colgroup>
          <col span="1" width="4%">
          <col span="1" width="30%">
          <col span="1" widht="50%">
          <col span="1" width="16%">
        </colgroup>

        <thead>
          <tr>
            <th v-for="(thName, index) in tableContent.headingRow" :key="index">
              {{ thName }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td v-for="index in getFirstRowLength" :key="index">
              {{ index }}
            </td>
          </tr>

            <tr v-for="(row, index) in tableContent.rows" :key="index">
              <td>{{ index + 1}} </td>
              <td style="text-align: left">
                <span v-html="row.text.replace(/\n/g, '<br />')"></span>
              </td>
              <td
                :class="{'table-section__input--disabled': isLastRow(index)}"
                width="50%"
              >
                <div v-if="!isLastRow(index)">
                  <br class="hide-in-app">
                  <div
                    v-for="(school, j) in $store.getters.tableSchools[formNumber]" :key="j">
                    <div
                      v-if="school == true"
                      class="table-section__input school__input"
                    >
                      <span class="hide-in-app">{{ $store.getters.tableData[page + '-' + formNumber + '-' + index + '-' + j + '-' + 0] }}<br><br class="hide-in-app"></span>
                      <input
                        type="text"
                        :class="index == tableContent.rows.length - 1 ? 'amount' : (index == 0) && (page == 0) ? 'options' : ''"
                        :id="'input-' + page + '-' + formNumber + '-' + index + '-' + j + '-' + 0"
                        :data-index="page + '-' + formNumber + '-' + index + '-' + j + '-' + 0"
                        :disabled="index != 0"
                        :data-row="index"
                        :data-col="0"
                        :data-school="j"
                        @input="updateModel(page + '-' + formNumber + '-' + index + '-' + j + '-' + 0, $event, true)"
                        :value="$store.getters.tableData[page + '-' + formNumber + '-' + index + '-' + j + '-' + 0]"
                      >
                      <v-icon
                        v-if="(index == 0) && (page == 0)"
                        color="red"
                        class="btn-delete-school"
                        tabindex="0"
                        @click="clickDeleteSchool(j)"
                      >
                        mdi-trash-can
                      </v-icon>
                      <rspo-api
                        v-if="(index == 0) && (page == 0)"
                        class="btn-get-school"
                        @onSuccess="getSchoolData($event, j)"
                      />
                      <!-- <v-icon
                        v-if="(index == 0) && (page == 0)"
                        color="green"
                        tabindex="0"
                        class="btn-get-school"
                        @click="clickDeleteSchool(j)"
                      >
                        mdi-school-outline
                      </v-icon> -->
                    </div>
                  </div>
                  <div class="table-section__input">
                    <span><strong>Suma:</strong><br></span>
                  </div>
                  <div
                    v-if="(index == 0) && (page == 0)"
                    class="table-section__input"
                  >
                    <span
                      class="btn-add-school"
                      @click="addSchool()"
                    >
                      Dodaj szkołę
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <div v-if="!isLastRow(index)">
                  <br class="hide-in-app">
                  <div
                    v-for="(school, j) in $store.getters.tableSchools[formNumber]" :key="j">
                    <div
                      v-if="school == true"
                      class="table-section__input"
                    >
                      <span>{{ $store.getters.tableData[page + '-' + formNumber + '-' + index + '-' + j + '-' + 1] }}<br><br class="hide-in-app"></span>
                      <input
                        type="text"
                        :class="index == tableContent.rows.length - 1 ? 'amount' : ''"
                        :id="'input-' + page + '-' + formNumber + '-' + index + '-' + j + '-' + 1"
                        :data-index="page + '-' + formNumber + '-' + index + '-' + j + '-' + 1"
                        :data-row="index"
                        :data-col="1"
                        @input="updateModel(page + '-' + formNumber + '-' + index + '-' + j + '-' + 1, $event, false)"
                        :value="formatValue($store.getters.tableData[page + '-' + formNumber + '-' + index + '-' + j + '-' + 1])"
                        placeholder="0,00"
                        style="border-bottom: 1px dotted #000; margin: 0px 15px"
                      >
                    </div>
                  </div>
                  <div class="table-section__input">
                    <span>{{ $store.getters.tableData[page + '-' + formNumber + '-' + index + '-sum-' + 1] }}<br></span>
                    <input
                      type="text"
                      :value="formatValue($store.getters.tableData[page + '-' + formNumber + '-' + index + '-sum-' + 1])"
                      disabled="disabled"
                      placeholder="0,00"
                    >
                  </div>
                </div>
                <div
                  v-if="isLastRow(index)"
                  class="table-section__input"
                >
                  <br>
                  <span>{{ $store.getters['user/userModel'].afterTableInputRow[page + '-' + formNumber] }}<br></span>
                  <input
                    type="text"
                    class="amount"
                    :value="formatValue($store.getters['user/userModel'].afterTableInputRow[page + '-' + formNumber])"
                    placeholder="0,00"
                    disabled="disabled"
                  >
                </div>
                <div
                  v-if="(index == 0) && (page == 0)"
                  class="table-section__input"
                >
                  <span class="school-break"></span>
                </div>
              </td>
            </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import tableMixin from '../../../mixins/tableMixin'
import RspoApiTable from './RspoApiTable'

export default {
  name: 'PageTable',

  data: function () {
    return {
      dialog: false,
      deleteIndex: -1,
      deleteName: ''
    }
  },

  components: {
    'rspo-api': RspoApiTable
  },

  props: {
    tableContent: Object,
    page: Number,
    formNumber: Number,
    tableNumber: Number
  },

  mixins: [
    tableMixin
  ],

  computed: {
    getFirstRowLength: function () {
      return Object.keys(this.tableContent.headingRow).length
    },

    textHasNotice: function () {
      return this.tableContent.notice.length > 0
        ? true
        : false
    },
  },

  methods: {
    // if last row, then disable table td in column
    isLastRow: function (index) {
      return this.tableContent.rows.length - 1 == index
        ? true
        :false
    },

    clickDeleteSchool(index) {
      this.deleteIndex = index
      this.dialog = true
      if(document.querySelector('input[data-school="' + index + '"]')) {
        this.deleteName = document.querySelector('input[data-school="' + index + '"]').value
      }
    },

    deleteSchool(schoolIndex) {
      //hide modal
      this.dialog = false

      //get element to delete
      let valueIndex = document.querySelector('input[data-school="' + schoolIndex + '"]').dataset.index
      valueIndex = valueIndex.substr(0, valueIndex.length - 1) + '1'
      let valueInput = document.querySelector('#input-' + valueIndex)

      //update store
      let model = this.$store.getters.tableData
      model[valueIndex] = 0
      this.$store.commit('updateTableData', model)

      this.$store.getters.tableSchools[this.formNumber][schoolIndex] = false

      //trigger change on delete element to recalculate current table
      let valueEvent = new Event('input', {
          bubbles: true,
          cancelable: true,
      });
      valueInput.value = 0
      valueInput.dispatchEvent(valueEvent)

      // this.reCalculate()

      this.$forceUpdate()
    },

    getSchoolData(response, index) {
      if(response.data) {
        var schoolName = response.data.nazwa + ', ' + response.data.ulica + ', ' + response.data.kodPocztowy + ' ' + response.data.miejscowosc + ', ' + response.data.regon
        document.querySelector('input[data-school="' + index + '"]').value = schoolName

        let model = this.$store.getters.tableData
        for(let pageNr = 0; pageNr < 3; pageNr++) {
          this.tableContent.rows.forEach((row, rowIndex) => {
            model[pageNr + '-' + this.formNumber + '-' + rowIndex + '-' + index + '-' + 0] = schoolName
          })
        }

        this.$store.commit('updateTableData', model)
        this.$forceUpdate()
      }
    },

    addSchool() {
      this.$store.getters.tableSchools[this.formNumber].push(true)
    },

    reCalculate() {
      let schoolAmount = this.$store.getters.tableSchools[this.formNumber].length
      let model = this.$store.getters.tableData

      for(var pageNr = 0; pageNr < 3; pageNr++) {
        this.colSum(model, schoolAmount, pageNr)
        this.form8Calculations(pageNr)
        this.tableSum(pageNr)
      }

      this.$store.commit('updateTableData', model)

      this.$forceUpdate()
    },

    updateModel (property, event, isSchoolName) {
      console.log(property, event, isSchoolName);

      let schoolAmount = this.$store.getters.tableSchools[this.formNumber].length
      let input = event.target
      if(!isSchoolName) {
        input.value = input.value.replace(/ /g,'').replace('.', ',')
      }
      let model = this.$store.getters.tableData
      model[property] = input.value

      this.form8Calculations()
      this.form9Calculations()

      if(isSchoolName) {
        //copy school name for all rows
        let schoolNr = input.dataset.school
        if(schoolNr) {
          for(let pageNr = 0; pageNr < 3; pageNr++) {
            this.tableContent.rows.forEach((row, rowIndex) => {
              model[pageNr + '-' + this.formNumber + '-' + rowIndex + '-' + schoolNr + '-' + 0] = input.value
            })
          }
        }
      } else {
          //cal sum of all fields in current col
          this.colSum(model, schoolAmount)
      }

      this.$store.commit('updateTableData', model)

      this.tableSum()

      // // last row and col - sum of fields
      // let allAmountsValues = 0
      // let rowAmountIndex = 5
      // if(((this.formNumber == 8) || (this.formNumber == 9)) && (this.page < 2)) {
      //   rowAmountIndex = 5
      // } else if(((this.formNumber == 8) || (this.formNumber == 9)) && (this.page == 2)) {
      //   rowAmountIndex = 6
      // }

      // for(var i = 0; i < schoolAmount; i++) {
      //   if(this.$store.getters.tableSchools[this.formNumber][i]) {
      //     let inputVal = model[this.page + '-' + this.formNumber + '-' + rowAmountIndex + '-' + i + '-' + 1]
      //     if(inputVal && !isNaN(parseFloat(inputVal))) {
      //       allAmountsValues += parseFloat(inputVal.replace(',', '.'))
      //     }
      //   }
      // }

      // let summaryData = {
      //   id: this.formNumber,
      //   page: this.page,
      //   value: allAmountsValues.toFixed(2).toLocaleString().replace('.', ',')
      // }
      // this.$store.commit('updateTableSum', summaryData)

      // let userModel = this.$store.getters['user/userModel']
      // userModel.afterTableInputRow[this.page + '-' + this.formNumber] = summaryData.value
      // this.$store.dispatch('user/updateModel', userModel)


      this.$forceUpdate()
    },

    tableSum(page) {
      page = page ? page : this.page // by default current page
      let schoolAmount = this.$store.getters.tableSchools[this.formNumber].length
      let model = this.$store.getters.tableData

      // last row and col - sum of fields
      let allAmountsValues = 0
      let rowAmountIndex = 5
      if(((this.formNumber == 8) || (this.formNumber == 9)) && (page < 2)) {
        rowAmountIndex = 5
      } else if(((this.formNumber == 8) || (this.formNumber == 9)) && (page == 2)) {
        rowAmountIndex = 6
      }

      for(var i = 0; i < schoolAmount; i++) {
        if(this.$store.getters.tableSchools[this.formNumber][i]) {
          let inputVal = model[page + '-' + this.formNumber + '-' + rowAmountIndex + '-' + i + '-' + 1]
          if(inputVal && !isNaN(parseFloat(inputVal))) {
            allAmountsValues += parseFloat(inputVal.replace(',', '.'))
          }
        }
      }


      let summaryData = {
        id: this.formNumber,
        page: page,
        value: allAmountsValues.toFixed(2).toLocaleString().replace('.', ',')
      }
      this.$store.commit('updateTableSum', summaryData)

      let userModel = this.$store.getters['user/userModel']
      userModel.afterTableInputRow[page + '-' + this.formNumber] = summaryData.value
      this.$store.dispatch('user/updateModel', userModel)

    },

    colSum(model, schoolAmount, page) {
      page = page ? page : this.page // by default current page
      this.tableContent.rows.forEach((row, rowIndex) => {
        let currentColSum = 0

        for(var i = 0; i < schoolAmount; i++) {
          if(this.$store.getters.tableSchools[this.formNumber][i]) { // check if school isn't deleted
            let inputVal = model[page + '-' + this.formNumber + '-' + rowIndex + '-' + i + '-' + 1]
            if(inputVal && !isNaN(parseFloat(inputVal))) {
              currentColSum += parseFloat(inputVal.replace(',', '.'))
            }
          }
        }

        model[page + '-' + this.formNumber + '-' + rowIndex + '-sum-' + 1] = currentColSum.toFixed(2).replace('.', ',')
      })

      return model
    },

    //calc sum of fields
    fieldsSum(fields, result) {
      let model = this.$store.getters.tableData
      let sum = 0
      let modelValue = 0;

      fields.forEach(field => {
        if(field.length == 2) {
          modelValue = model[this.page + '-' + this.formNumber + '-' + field[0] + '-' + field[1] + '-' + 1]
          if(modelValue && !isNaN(parseFloat(modelValue))) {
            let tmp = parseFloat(modelValue.replace(',', '.'))
            sum += tmp
          }
        }
      })

      sum = sum.toFixed(2).replace('.', ',')

      if(result && result.length == 2) {
        let resultIndex = this.page + '-' + this.formNumber + '-' + result[0] + '-' + result[1] + '-' + 1
        model[resultIndex] = sum
        this.$store.commit('updateTableData', model)

        let resultInput = document.querySelector('input[data-index="' + resultIndex + '"]')
        if(resultInput) {
          document.querySelector('input[data-index="' + resultIndex + '"]').value = sum
        }
      }

      return sum
    },

    //calc sum of fields
    fieldsDiff(fields, result) {
      let model = this.$store.getters.tableData
      let diff = 0
      let modelValue = 0;

      //$store.getters.tableData[page + '-' + formNumber + '-' + index + '-' + j + '-' + 1]

      fields.forEach((field, index) => {
        if(field.length == 2) {
          modelValue = model[this.page + '-' + this.formNumber + '-' + field[0] + '-' + field[1] + '-' + 1]
          if(modelValue && !isNaN(parseFloat(modelValue))) {
            if(index == 0) {
              diff = parseFloat(modelValue.replace(',', '.'))
            } else {
              let tmp = parseFloat(modelValue.replace(',', '.'))
              diff -= tmp
            }
          }
        }
      })

      diff = diff.toFixed(2).replace('.', ',')

      if(result && result.length == 2) {
        let resultIndex = this.page + '-' + this.formNumber + '-' + result[0] + '-' + result[1] + '-' + 1
        model[resultIndex] = diff
        this.$store.commit('updateTableData', model)

        let resultInput = document.querySelector('input[data-index="' + resultIndex + '"]')
        if(resultInput) {
          document.querySelector('input[data-index="' + resultIndex + '"]').value = diff
        }
      }

      return diff
    },

    form8Calculations(page) {
      page = page ? page : this.page // by default current page
      let schoolAmount = this.$store.getters.tableSchools[this.formNumber].length

      for(let i = 0; i < schoolAmount; i++) {
        if(this.$store.getters.tableSchools[this.formNumber][i]) { // check if school isn't deleted
          if((this.formNumber == 8) && (page == 0)) {
            this.fieldsDiff([[0, i],[1, i]], [2, i])
            this.fieldsDiff([[1, i],[3, i]], [4, i])
            this.fieldsSum([[2, i], [4, i]], [5, i])
          } else if((this.formNumber == 8) && (page== 1)) {
            this.fieldsDiff([[0, i],[1, i]], [2, i])
            this.fieldsDiff([[1, i],[3, i]], [4, i])
            this.fieldsSum([[2, i], [4, i]], [5, i])
          } else if((this.formNumber == 8) && (page == 2)) {
            this.fieldsDiff([[0, i],[1, i]], [2, i])
            this.fieldsDiff([[3, i],[4, i]], [5, i])
            this.fieldsSum([[2, i], [5, i]], [6, i])
          }
        }
      }
    },

    form9Calculations() {
      let schoolAmount = this.$store.getters.tableSchools[this.formNumber].length

      for(let i = 0; i < schoolAmount; i++) {
        if(this.$store.getters.tableSchools[this.formNumber][i]) { // check if school isn't deleted
          if((this.formNumber == 9) && (this.page == 0)) {
            this.fieldsDiff([[0, i],[1, i]], [2, i])
            this.fieldsDiff([[1, i],[3, i]], [4, i])
            this.fieldsSum([[2, i], [4, i]], [5, i])
          } else if((this.formNumber == 9) && (this.page == 1)) {
            this.fieldsDiff([[0, i],[1, i]], [2, i])
            this.fieldsDiff([[1, i],[3, i]], [4, i])
            this.fieldsSum([[2, i], [4, i]], [5, i])
          } else if((this.formNumber == 9) && (this.page == 2)) {
            this.fieldsDiff([[0, i],[1, i]], [2, i])
            this.fieldsDiff([[3, i],[4, i]], [5, i])
            this.fieldsSum([[2, i], [5, i]], [6, i])
          }
        }
      }
    }
  }
}
</script>