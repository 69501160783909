<template>
  <div id="pdf-pages">
    <pdf-intro
      :pdfPageIndex="pdfPageIndex"
      :pdfPagesLength="getPdfPagesLength"
      :formNumber="5"
      form="Formularz 5"
    />

    <page-one
      v-if="isFirstPage"

      :attachmentName="content.attachmentName"
      :attachmentNumber="content.attachmentNumber"
      :pageTitle="content.pageTitle"
      :mainTitle="content.mainTitle"

      :instituteName="content.instituteName"
      :notice="content.notice"

      :localGovermentUnit="content.localGovermentUnit"
      :localGovermentUnitOnly="content.localGovermentUnitOnly"
      :bottomInfoText="content.bottomInfoText"

      :checkboxes="content.checkboxes"

      v-model="userModel"
    />

    <page-shared
      v-else-if="!isFirstPage && isLastPage"
      :content="pdfPages[pdfPageIndex]"
      v-model="userModel"
      :page="pdfPageIndex"
      :formNumber="5"
    />

    <page-last
      v-if="!isLastPage"
      :content="pdfPages[pdfPageIndex]"
      :pdfContent="content"
      :formNumber="5"
    />

    <pdf-footnote
      v-if="isFootnote"
      :footnoteContent="getFootnote"
    />

    <page-switcher
      :pdfPageIndex="pdfPageIndex"
      :pdfPagesLength="getPdfPagesLength"
      @pageChanged="changePage"
      @generatePdf="generatePdf"
    />

  </div>
</template>

<script>
import PageOne from '../Shared/Pdf-1,2,4,5/PageOne'
import PageLast from '../Shared/Pdf-1,2,4,5/PageLast'
import PdfPageSwitcher from '../Shared/PdfPageSwitch'
import PdfFootnote from '../Shared/PdfFootnote'
import PdfIntro from '../Shared/PdfIntro';
import PageShared from '../Shared/Pdf-1,2,4,5/Page'
import jsPdfMixin from '../../mixins/jsPdfMixin'

export default {
  name: 'Pdf5',

  data: function () {
    return {
      content: {},
      pdfPages: null,

      pdfPageIndex: -1,
      currentPdfPage: {},

      userModel: {
        instituteName: '',
        address: '',
        regon: '',
        localGovermentUnit: '',
        terytCode: '',
        priceSummary: '',
        studentsSummary: ''
      }
    }
  },

  props: ['pdfContent'],

  mixins: [jsPdfMixin],

  components: {
    'page-one': PageOne,
    'page-switcher': PdfPageSwitcher,
    'pdf-footnote': PdfFootnote,
    'pdf-intro': PdfIntro,
    'page-shared': PageShared,
    'page-last': PageLast
  },

  created: function () {
    this.content = this.$store.getters['pdf/pdfContent']
    this.pdfPageIndex = this.$store.getters['pdf/pageIndex']

    if (this.content == null) {
      this.content = this.pdfContent
    }

    if (this.content.attachmentNumber != 'Załącznik nr 5') {
      this.content = this.pdfContent
    }

    this.pdfPages = this.content.content.pages
    this.currentPdfPage = this.content[this.pdfPageIndex]

    this.initializeDoc()
  },

  computed: {
    getPdfPagesLength () {
      return this.pdfPages.length
    },

    isFirstPage () {
      return this.pdfPageIndex > -1
        ? false
        : true
    },

    isLastPage () {
      return this.pdfPageIndex >= this.pdfPages.length - 1
        ? false
        : true
    },
    isFootnote () {
      if (this.isFirstPage) {
        return this.content.footnote != null
          ? true
          : false
      }
      return this.pdfPages[this.pdfPageIndex].footnote != null
        ? true
        : false
    },

    getFootnote () {
      if (!this.isFootnote) {
        return
      }

      if (this.isFirstPage) {
        return this.content.footnote
      }

      return this.pdfPages[this.pdfPageIndex].footnote
    }
  },

  watch: {
    pdfPageIndex: function () {
      this.currentPdfPage = this.pdfPages[this.pdfPageIndex]
    }
  },

  methods: {
    changePage (index) {
      // means thats we were on the first page
      if ((index - 1) == -1 ) {
        this.pdfPageIndex = index
        this.initializeDoc()
        this.createFirstPage()
        return
      }

      this.createPdfTable(this.pdfPageIndex)
      this.pdfPageIndex = index
    },

    generatePdf() {
      this.savePdfPage()
    },
  }
}
</script>