<template>
  <div>
    <pdf-intro
      :pdfPageIndex="pdfPageIndex"
      :pdfPagesLength="getPdfPagesLength"
      :formNumber="7"
      form="Formularz 7"
    />

    <div id="pdf-pages">
      <page-one
        v-if="isFirstPage"
        :pdfContent="content"
        :attachmentName="content.attachmentName"
        :attachmentNumber="content.attachmentNumber"
        :pageTitle="content.pageTitle"
        :mainTitle="content.mainTitle"

        :instituteName="content.instituteName"
        :notice="content.notice"

        :localGovermentUnit="content.localGovermentUnit"
        :localGovermentUnitOnly="content.localGovermentUnitOnly"
        :bottomInfoText="content.bottomInfoText"
        :checkboxes="content.checkboxes"

        v-model="userModel"
      />

      <page-shared
        v-else-if="!isFirstPage"
        :content="pdfPages[pdfPageIndex]"
        v-model="userModel"
        :page="pdfPageIndex"
        :formNumber="7"
      />

      <pdf-signs
        v-if="!isLastPage"
        :extended="pdfPages[pdfPageIndex].signExtended"
        :pdfContent="content"
      />

      <pdf-footnote
        v-if="isFootnote"
        :footnoteContent="getFootnote"
      />

      <page-switcher
        :pdfPageIndex="pdfPageIndex"
        :pdfPagesLength="getPdfPagesLength"
        @pageChanged="changePage"
        @generatePdf="generatePdf"
      />
    </div>
  </div>
</template>


<script>
import PdfPageSwitcher from '../Shared/PdfPageSwitch'
import PdfFootnote from '../Shared/PdfFootnote'
import PageOne from '../Shared/Pdf-6,7/PageOne'
import PageShared from '../Shared/Pdf-6,7/Page'
import PdfIntro from '../Shared/PdfIntro'
import PdfSigns from  '../Shared/PdfSigns'
import jsPdfMixin from '../../mixins/jsPdfMixin'

export default {
  name: 'Pdf7',

  data: function() {
    return {
      content: {},
      pdfPages: null,

      pdfPageIndex: -1,
      currentPdfPage: {},

      userModel: {
        instituteName: '',
        address: '',
        regon: '',
        localGovermentUnit: '',
        terytCode: '',
        priceSummary: '',
        studentsSummary: ''
      }
    }
  },

  props: ['pdfContent'],

  mixins: [jsPdfMixin],

  components: {
    'page-one': PageOne,
    'page-shared': PageShared,
    'page-switcher': PdfPageSwitcher,
    'pdf-intro': PdfIntro,
    'pdf-footnote': PdfFootnote,
    'pdf-signs': PdfSigns
  },

  created: function () {
    this.content = this.$store.getters['pdf/pdfContent']
    this.pdfPageIndex = this.$store.getters['pdf/pageIndex']

    if (this.content == null) {
      this.content = this.pdfContent
    }

    if (this.content.attachmentNumber != 'Załącznik nr 7') {
      this.content = this.pdfContent
    }

    this.pdfPages = this.content.content.pages
    this.currentPdfPage = this.content[this.pdfPageIndex]

    this.initializeDoc()
  },

  computed: {
    getPdfPagesLength () {
      return this.pdfPages.length
    },

    isFirstPage () {
      return this.pdfPageIndex > -1
        ? false
        : true
    },

    isLastPage () {
      return this.pdfPageIndex >= this.pdfPages.length - 1
        ? false
        : true
    },
    isFootnote () {
      if (this.isFirstPage) {
        return this.content.footnote != null
          ? true
          : false
      }
      return this.pdfPages[this.pdfPageIndex].footnote != null
        ? true
        : false
    },

    getFootnote () {
      if (!this.isFootnote) {
        return
      }

      if (this.isFirstPage) {
        return this.content.footnote
      }

      return this.pdfPages[this.pdfPageIndex].footnote
    }
  },

  watch: {
    pdfPageIndex: function () {
      this.currentPdfPage = this.pdfPages[this.pdfPageIndex]
    }
  },

  methods: {
    changePage (index) {
      // means thats we were on the first page
      if ((index - 1) == -1 ) {
        this.pdfPageIndex = index
        this.initializeDoc()
        this.createFirstPage()
        return
      }
      this.createPdfTable(this.pdfPageIndex)
      this.pdfPageIndex = index
    },

    generatePdf() {
      this.savePdfPageWithTable(this.pdfPageIndex)
    }
  }
}
</script>